import { createBrowserRouter, createRoutesFromElements, Route} from 'react-router-dom';
import About from '../components/About';
import ErrorPage from '../components/ErrorPage';
import Navbar from '../components/navbar/Navbar';
import RootLayout from '../components/RootLayout';
import Home from '../components/Home';
import Dogs from '../components/Dogs';
import Beers from '../components/Beers';
import Puns from '../components/Puns';
import Contact from '../components/Contact';
import Code from '../components/Code';
import Tools from '../components/Tools';
import LunchSelector from '../components/tools/LunchSelector';


const appRouter = createBrowserRouter(createRoutesFromElements(
    <Route exact path="/" element={ <RootLayout /> } errorElement={ <ErrorPage />} >
      <Route index element={<Home />} />
      <Route path="about" element={ <About /> } errorElement={ <ErrorPage />} />
      <Route path="navbar" element={ <Navbar/> } errorElement={ <ErrorPage />} />
      <Route path="dogs" element={ <Dogs/> } errorElement={ <ErrorPage />} />
      <Route path="beers" element={ <Beers/> } errorElement={ <ErrorPage />} />
      <Route path="puns" element={ <Puns/> } errorElement={ <ErrorPage />} />
      <Route path="contact" element={ <Contact/> } errorElement={ <ErrorPage />} />
      <Route path="code" element={ <Code/> } errorElement={ <ErrorPage />} />
      <Route path="tools" element={ <Tools/> } errorElement={ <ErrorPage />} />
      <Route path="/tools/lunchselector" element={ <LunchSelector/> } errorElement={ <ErrorPage />} />
    </Route>
  ));
  
  export default appRouter;