import './App.css';
import * as React from "react";
import {
  RouterProvider,
} from "react-router-dom";
import appRouter from './routes/appRouter';
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

let lightTheme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#90caf9',
      light: '#A6D4FA',
      dark: '#648DAE',
      contrastText: 'rgba(0,0,0,0.87)',
    },
    secondary: {
      main: '#f48fb1',
      light: '#F6A5C0',
      dark: '#AA647B',
      contrastText: 'rgba(0,0,0,0.87)',
    },
    background: {
      default: '#212121',
      paper: '#424242',
    },
    text: {
      primary: '#FFFFFF',
      secondary: 'rgba(255,255,255,0.7)',
      disabled: 'rgba(255,255,255,0.5)',
      hint: 'rgba(255,255,255,0.3)',
    },
    divider: 'rgba(255,255,255,0.12)',
    warning: {
      main: '#ff9800',
      light: '#ffac33',
      dark: '#B26A00',
      contrastText: 'rgba(0,0,0,0.87)',
    },
    error: {
      main: '#f44336',
      light: '#f6685e',
      dark: '#AA2E25',
      contrastText: '#ffffff',
    },
    info: {
      main: '#2196f3',
      light: '#4DABF5',
      dark: '#1769AA',
      contrastText: '#FFFFFF',
    },
    success: {
      main: '#4caf50',
      light: '#6FBF73',
      dark: '#357A38',
      contrastText: 'rgba(0,0,0,0.87)',
    },
  },
  typography: {
    fontSize: 20,
  }
});

lightTheme = responsiveFontSizes(lightTheme);

const router = appRouter;

function App() {

  return (
    <div className="App">
      <ThemeProvider theme={lightTheme}>
    <CssBaseline />        
      <RouterProvider router={router} />
      </ThemeProvider>
    </div>
  );
}

export default App;
