import { useTheme } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

const tylerHiggsDev = [
    {
        img: '/Code/TylerHiggsDev.png',
        title: 'Personal Website',
      },
      {
        img: '/Code/THDContact.png',
        title: 'Contact Page',
      },
      {
        img: '/Code/THDAbout.png',
        title: 'About Page',
      }
]

const baffler= [
    {
        img: '/Code/Baffler.png',
        title: 'Riddle Game',
      },
      {
        img: '/Code/BafTacToe.png',
        title: 'Working Tic Tac Toe Game',
      },
      {
        img: '/Code/BafLevel2.png',
        title: 'Level 2',
      },
      {
        img: '/Code/BafSimon.png',
        title: 'Working Simon Memory Game',
      },
      {
        img: '/Code/BafLevel8.png',
        title: 'Level 8',
      }
]

const linnCoIce= [
    {
        img: '/Code/LinnCoIce.png',
        title: 'Charity Console App',
      }
]

const subaFun = [
    {
      img: '/Code/SubaFun1.png',
      title: 'Home Screen',
    },
    {
        img: '/Code/SubaSpanishErrorless.png',
        title: 'New concepts presented errorlessly',
      },
    {
      img: '/Code/SubaFun2.png',
      title: 'Early Numeracy (Subatization)',
    },
    {
      img: '/Code/SubaFun3.png',
      title: 'Points awarded for remembering old concepts',
    },
    {
      img: '/Code/SubaFun4.png',
      title: 'Trophy Store',
    }
  ];

  const quidQuo = [
    {
        img: '/Code/QQHome.png',
        title: 'Home Page',
      },
    {
      img: '/Code/QuidQuo.png',
      title: 'Finance Search',
    },
    {
      img: '/Code/QuidQuo2.png',
      title: 'Data Visualization',
    },
    {
        img: '/Code/QQBlogPosts.png',
        title: 'Listing of Blog Posts',
      },
      {
        img: '/Code/QQPost.png',
        title: 'Blog Post about the Finance Search Tool',
      }
  ];



export default function Code (){
    const theme = useTheme();

    return(
        <div>
            <Typography variant="h3" color={theme.palette.secondary.main}>Code</Typography>
<br></br>
<br></br>
            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={12} md={3}>
                <Typography color={theme.palette.primary.main}>Nov 2023</Typography></Grid>
                <Grid item xs={12} md={6}>
                <a href='/'><Typography color={theme.palette.secondary.main}>TylerHiggs.Dev</Typography></a>
                <ImageList variant="woven" cols={3} rowHeight={164} align='center'>
      {tylerHiggsDev.map((item) => (
        <ImageListItem key={item.img}>
          <img
            srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
            src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
            alt={item.title}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
                </Grid>
                <Grid item xs={12} md={3}><Typography color={theme.palette.primary.main}><a href='/'>TylerHiggs.Dev</a> is a personal profile page currently under construction. Created in ReactJS with the MaterialUI Library.</Typography></Grid>
            </Grid>
            <br></br>
<br></br>
            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={12} md={3}>
                <Typography color={theme.palette.primary.main}>Sept 2023</Typography></Grid>
                <Grid item xs={12} md={6}>
                <a href='https://anotherinternetriddlegame.com/'><Typography color={theme.palette.secondary.main}>Baffler</Typography></a>
                <ImageList variant="woven" cols={3} rowHeight={164} align='center'>
      {baffler.map((item) => (
        <ImageListItem key={item.img}>
          <img
            srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
            src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
            alt={item.title}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
                </Grid>
                <Grid item xs={12} md={3}><Typography color={theme.palette.primary.main}><a href='https://anotherinternetriddlegame.com/'>Baffler: Another Internet Riddle Game</a> is an homage to all the classic internet riddle games that I loved when I was growing up. Created in ReactJS with the MaterialUI Library.</Typography></Grid>
            </Grid>
            <br></br>
<br></br>
            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={12} md={3}>
                <Typography color={theme.palette.primary.main}>June 2020</Typography></Grid>
                <Grid item xs={12} md={6}>
                <Typography color={theme.palette.secondary.main}>LinnCo_ICE</Typography>
                <ImageList variant="woven" cols={3} rowHeight={164} align='center'>
      {linnCoIce.map((item) => (
        <ImageListItem key={item.img}>
          <img
            srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
            src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
            alt={item.title}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
                </Grid>
                <Grid item xs={12} md={3}><Typography color={theme.palette.primary.main}>LinnCo_ICE is a simple console application made for a legal defense charity. It scrapes web information and provides a report to identify arrested persons potentially in need of legal assistance.</Typography></Grid>
            </Grid>
            <br></br>
<br></br>
            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={12} md={3}>
                <Typography color={theme.palette.primary.main}>April 2020</Typography></Grid>
                <Grid item xs={12} md={6}>
                <Typography color={theme.palette.secondary.main}>SubaFun</Typography>
                <ImageList variant="woven" cols={3} rowHeight={164} align='center'>
      {subaFun.map((item) => (
        <ImageListItem key={item.img}>
          <img
            srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
            src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
            alt={item.title}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
                </Grid>
                <Grid item xs={12} md={3}><Typography color={theme.palette.primary.main}>SubaFun is a mobile application created in React Native as a proof of concept application for errorless learning strategies. The application demonstrates the breadth of value in these strategies for tasks as simple as early numeracy skills to learning Klingon vocabulary.</Typography></Grid>
            </Grid>
            <br></br>
<br></br>
            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={12} md={3}>
                <Typography color={theme.palette.primary.main}>Sept 2019</Typography></Grid>
                <Grid item xs={12} md={6}>
                <a href='https://www.quidquo.org/'><Typography color={theme.palette.secondary.main}>QuidQuo</Typography></a>
                <ImageList variant="woven" cols={3} rowHeight={164} align='center'>
      {quidQuo.map((item) => (
        <ImageListItem key={item.img}>
          <img
            srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
            src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
            alt={item.title}
            
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
                </Grid>
                <Grid item xs={12} md={3}><Typography color={theme.palette.primary.main}><a href='https://www.quidquo.org/'>QuidQuo.org</a> is a website created in Bootstrap Javascript that aggregates data regarding Iowa Candidates for public office and presents visualizations about who donates to their campaign. It also features a blog component created with Jekyll.</Typography></Grid>
            </Grid>
            <Typography color={theme.palette.secondary.main}>You can view additional personal projects at my <a href='https://github.com/Thiggs'>Github</a> or <a href='https://codepen.io/thiggs'>Codepen</a></Typography>
        </div>
    )
};