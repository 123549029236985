import untappdFiltered from "../constants/UntappdFiltered"
import { Grid, Typography } from '@mui/material'
import { useTheme } from '@emotion/react'

export default function Beers (){
    const theme = useTheme();
    
    return(
        <div>
            <Typography variant="h3" color={theme.palette.secondary.main}>Beers</Typography>
            <Typography color={theme.palette.primary.main}>A listing of all beers I've ranked better than a 4/5 on Untappd</Typography>
            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={1} md={3}></Grid>
                <Grid item xs={10} md={6}>
      {untappdFiltered.map((item) => (
        <div key={item.beer_name}>
            <br></br>
<Typography variant="h5" color={theme.palette.primary.main} a href={item.beer_url}>{item.beer_name}</Typography>
<div><Typography a href={item.brewery_url} color={theme.palette.secondary.main} display="inline">Brewery:</Typography><Typography display="inline"> {item.brewery_name}, {item.brewery_country}</Typography></div>
<div><Typography display="inline" color={theme.palette.secondary.main}>Beer Type:</Typography> <Typography display="inline">{item.beer_type}</Typography> </div>
<div><Typography display="inline" color={theme.palette.secondary.main}>ABV:</Typography> <Typography display="inline"> {item.beer_abv},</Typography> <Typography display="inline" color={theme.palette.secondary.main}> IBU:</Typography><Typography display="inline"> {item.beer_ibu}</Typography></div>
<div><Typography display="inline" color={theme.palette.secondary.main}>My Rating:</Typography> <Typography display="inline">{item.rating_score},</Typography> <Typography display="inline" color={theme.palette.secondary.main}>Global Rating (Untappd):</Typography><Typography display="inline"> {item.global_rating_score}</Typography></div>
</div>
      )
      )}
    </Grid>
    <Grid item xs={1} md={3}></Grid>
    </Grid>
        </div>
    )
};