const punList =
[
    {
        "topic": "'Nuts and Berries' and Chemistry",
        "puns": [
            "Safety is imperative when working with chemicals. Don't let them cashew without your goggles on.",
            "Did you hear about the chemist who tried to synthesize a molecule with phosphorous, nitrogen, uranium, and tennissine? They were working for P-NUTs",
            "I'm trying to come up with a chemistry pun about berries but can't think of any that will get a reaction.",
            "Chemistry is always changing. It's hard to stay *current* in the field.",
            "Grapes are berries with a unique chemical structure. For example, when you dehydrate them, you begin raisin some serious questions about their structural integrity, but when you ferment them, they really have something to wine about.",
            "My wife said my puns about berries are making our chemistry tank. I guess they aren't berry good.",
            "If berries are a problem, are smoothies a solution?",
            "Academia is a community that includes chemists and botanists. Macademia is where they specialize.",
            "These mixed nuts taste like metal. I think they're mostly alumondnims"
        ]
    },
    {
        "topic": "Musical Instruments and Ice Cream",
        "puns": [
            "Vanilla's not supposed to be yellow. Someone piano ice cream.",
            "Some people think ice cream is better than sax.",
            "Oboe! My ice cream fell off the cone!",
            "Chocolate is a really good ice cream flavor. It's hard to trumpet.",
            "Did you hear what instruments he spilled his ice cream on? Violins is not the answer.",
            "Warning: this ice cream contains Xylitolphone (sorry, this one is objectively bad)",
            "He thought he was drumming on a pint of ice cream, but it was frozen percustard",
            "I know Baskin Robbins has 31 flavors, but I always choose from the first 3. I like to piccolo one",
            "I ate a whole tub-a ice cream",
            "I gotta have more Blue bell (in my best Christopher Walken)",
            "If you want to make ice cream, you gotta shake a few eggs."
        ]
    },
    {
        "topic": "Black Friday Movie Titles",
        "puns": [
            "The Mall-Shank Hypertension",
            "Swindler's List",
            "The Lord of Receipts: The Return of the Thing",
            "This year, there were deals so good, it made me sob. I'm telling you, Savings, Quiet Cryin'.",
            "In 1999, the hot new item was made by Sega. It was the Millennium for a Dreamcast.",
            "Did you hear about how they set small fires in buildings for tickle-me-elmos? They were quaint Elmo Fires.",
            "Tic-kill Elmo-ckingbird",
            "(reply to joke about Amazon) Really turned into an O Bezos, Where Art Thou situation.",
            "Good Deal Hunting"
        ]
    },
    {
        "topic": "Thanksgiving",
        "puns": [
            "If you want to test out your thanksgiving puns, feel free to use this thread as your dressing room.",
            "The turkey is really glad this is a pun thread instead of a roast.",
            "Yes, you Macy some bad puns, but just feel free to parade around whatever puns you've got.",
            "I could just gobble up pun threads",
            "Sorry, I was out and about and unable to keep a-breast with the puns that were happening here.",
            "(in response to another pun) that pun was definitely a cut and baste job.",
            "There's still thyme left to get your puns in.",
            "You can just wing it if you want",
            "Some people get salty about pun threads, but I think they're just brine.",
            "I'm really stuffing this one full, but these puns are like gravy.",
            "When you try to stretch a pun too far, some people don't think it's funny but others still like it. In other words, results cranberry.",
            "Sorry if these are corny.",
            "Went out to the country to celebrate Thanksgiving. They told me my dessert was made with sweet potatoes, but I think that was a bumpkin lie.",
            "Had Thanksgiving at a youth group, and they served a non-alcoholic communion. Just a bunch of clean teen pastor rolls.",
            "Tried to eat thanksgiving at the rock concert, but didn't care for the moshed potatoes.",
            "Tried to convince my Belgian friend that Thanksgiving vegetables are actually pretty good, but I couldn't assuage their Brussels doubts.",
            "I went to a realtor's house for Thanksgiving, and the place was immaculate. It was really tur-key ready."
        ]
    }
]

export default punList;