const untappdFiltered =
[
    {
        "beer_name": "Sumatra Coffee Stout",
        "brewery_name": "Madhouse Brewing Company",
        "beer_type": "Stout - Coffee",
        "beer_abv": "6",
        "beer_ibu": "0",
        "rating_score": "5",
        "beer_url": "https://untappd.com/beer/753011",
        "brewery_url": "https://untappd.com/brewery/1521",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2014_10_04/1e0c154156fedd74f90e78d4e9686045_raw.jpg",
        "global_rating_score": 3.68
    },
    {
        "beer_name": "Hello World",
        "brewery_name": "Hello World Brewery (Homebrew)",
        "beer_type": "Wheat Beer - American Pale Wheat",
        "beer_abv": "4.5",
        "beer_ibu": "7",
        "rating_score": "5",
        "beer_url": "https://untappd.com/beer/1495717",
        "brewery_url": "https://untappd.com/brewery/261808",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2016_04_03/64a7065bb46124d3c0552205032f6cba_raw.jpg",
        "global_rating_score": 0
    },
    {
        "beer_name": "Summer Shandy",
        "brewery_name": "Jacob Leinenkugel Brewing Company",
        "beer_type": "Shandy / Radler",
        "beer_abv": "4.2",
        "beer_ibu": "14",
        "rating_score": "4.75",
        "beer_url": "https://untappd.com/beer/8949",
        "brewery_url": "https://untappd.com/brewery/708",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2015_07_10/11ed7d9b46fe63e62b3b5786c1bd528d_raw.jpg",
        "global_rating_score": 3.52
    },
    {
        "beer_name": "Breck IPA",
        "brewery_name": "Breckenridge Brewery",
        "beer_type": "IPA - American",
        "beer_abv": "6.3",
        "beer_ibu": "66",
        "rating_score": "4.75",
        "beer_url": "https://untappd.com/beer/461373",
        "brewery_url": "https://untappd.com/brewery/236",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2015_07_30/8cc911279bef784b3571914899d207c7_raw.jpg",
        "global_rating_score": 3.52
    },
    {
        "beer_name": "Blackberry Melomel",
        "brewery_name": "Moonstruck Meadery",
        "beer_type": "Mead - Melomel",
        "beer_abv": "10.5",
        "beer_ibu": "0",
        "rating_score": "4.75",
        "beer_url": "https://untappd.com/beer/199707",
        "brewery_url": "https://untappd.com/brewery/17941",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2015_09_11/0962e2a594a7281daf487794119ff2eb_raw.jpg",
        "global_rating_score": 3.81
    },
    {
        "beer_name": "Arrogant Bastard Ale",
        "brewery_name": "Arrogant Consortia",
        "beer_type": "Strong Ale - American",
        "beer_abv": "7.2",
        "beer_ibu": "100",
        "rating_score": "4.75",
        "beer_url": "https://untappd.com/beer/18099",
        "brewery_url": "https://untappd.com/brewery/234399",
        "brewery_country": "United States",
        "photo_url": null,
        "global_rating_score": 3.69
    },
    {
        "beer_name": "Coffee Stout",
        "brewery_name": "Madhouse Brewing Company",
        "beer_type": "Stout - Coffee",
        "beer_abv": "6",
        "beer_ibu": "0",
        "rating_score": "4.75",
        "beer_url": "https://untappd.com/beer/936920",
        "brewery_url": "https://untappd.com/brewery/1521",
        "brewery_country": "United States",
        "photo_url": null,
        "global_rating_score": 3.67
    },
    {
        "beer_name": "Caramel Cinnamon",
        "brewery_name": "One Tree Hard Cider",
        "beer_type": "Cider - Herbed / Spiced / Hopped",
        "beer_abv": "6.8",
        "beer_ibu": "0",
        "rating_score": "4.75",
        "beer_url": "https://untappd.com/beer/865161",
        "brewery_url": "https://untappd.com/brewery/141397",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2017_09_02/9236ac5301495a0f000ac4054856ce46_raw.jpeg",
        "global_rating_score": 4.07
    },
    {
        "beer_name": "Peanut Butter Porter",
        "brewery_name": "Lake Time Brewery",
        "beer_type": "Porter - American",
        "beer_abv": "5.2",
        "beer_ibu": "33",
        "rating_score": "4.75",
        "beer_url": "https://untappd.com/beer/1566561",
        "brewery_url": "https://untappd.com/brewery/69372",
        "brewery_country": "United States",
        "photo_url": null,
        "global_rating_score": 3.88
    },
    {
        "beer_name": "Cat Dragon",
        "brewery_name": "Firetrucker Brewery",
        "beer_type": "Hard Ginger Beer",
        "beer_abv": "13.5",
        "beer_ibu": "0",
        "rating_score": "4.75",
        "beer_url": "https://untappd.com/beer/1144632",
        "brewery_url": "https://untappd.com/brewery/126926",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photos/2018_08_19/78a7ad37591bfcbe7f769f0deaa9beae_raw.jpeg",
        "global_rating_score": 3.69
    },
    {
        "beer_name": "Mountain Water Citrus Splash",
        "brewery_name": "Hi-Wire Brewing",
        "beer_type": "Other",
        "beer_abv": "4.5",
        "beer_ibu": "5",
        "rating_score": "4.75",
        "beer_url": "https://untappd.com/beer/4525691",
        "brewery_url": "https://untappd.com/brewery/64692",
        "brewery_country": "United States",
        "photo_url": null,
        "global_rating_score": 3.4
    },
    {
        "beer_name": "Hopititis",
        "brewery_name": "515 Brewing Company",
        "beer_type": "IPA - Imperial / Double",
        "beer_abv": "9.5",
        "beer_ibu": "85",
        "rating_score": "4.5",
        "beer_url": "https://untappd.com/beer/519153",
        "brewery_url": "https://untappd.com/brewery/24774",
        "brewery_country": "United States",
        "photo_url": null,
        "global_rating_score": 4.07
    },
    {
        "beer_name": "Stone IPA",
        "brewery_name": "Stone Brewing",
        "beer_type": "IPA - American",
        "beer_abv": "6.9",
        "beer_ibu": "71",
        "rating_score": "4.5",
        "beer_url": "https://untappd.com/beer/821",
        "brewery_url": "https://untappd.com/brewery/1204",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2014_10_04/c82b7d560a863c7e8da1be711b66e01d_raw.jpg",
        "global_rating_score": 3.75
    },
    {
        "beer_name": "Pumpkin Ale",
        "brewery_name": "Schlafly - The Saint Louis Brewery",
        "beer_type": "Pumpkin / Yam Beer",
        "beer_abv": "8",
        "beer_ibu": "16",
        "rating_score": "4.5",
        "beer_url": "https://untappd.com/beer/8263",
        "brewery_url": "https://untappd.com/brewery/1092",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2014_10_04/aa0f13569e7f13e05a58916cd5cff219_raw.jpg",
        "global_rating_score": 3.91
    },
    {
        "beer_name": "Jack-O Traveler Pumpkin Shandy",
        "brewery_name": "Traveler Beer Co.",
        "beer_type": "Shandy / Radler",
        "beer_abv": "4.4",
        "beer_ibu": "7",
        "rating_score": "4.5",
        "beer_url": "https://untappd.com/beer/405755",
        "brewery_url": "https://untappd.com/brewery/27980",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2014_11_02/ec217123531f544d456ec2f44a8c1692_raw.jpg",
        "global_rating_score": 3.59
    },
    {
        "beer_name": "Pêche / Pêcheresse",
        "brewery_name": "Brouwerij Lindemans",
        "beer_type": "Lambic - Fruit",
        "beer_abv": "2.5",
        "beer_ibu": "10",
        "rating_score": "4.5",
        "beer_url": "https://untappd.com/beer/2327",
        "brewery_url": "https://untappd.com/brewery/288",
        "brewery_country": "Belgium",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2015_05_30/7d8316772ca33384a3892ddf38c3dbc9_raw.jpg",
        "global_rating_score": 3.57
    },
    {
        "beer_name": "Ginger Lemon Radler",
        "brewery_name": "Boulevard Brewing Co.",
        "beer_type": "Shandy / Radler",
        "beer_abv": "4.1",
        "beer_ibu": "13",
        "rating_score": "4.5",
        "beer_url": "https://untappd.com/beer/594084",
        "brewery_url": "https://untappd.com/brewery/1514",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2015_07_07/38fe365714ceedbc73bcc7955c7ae84d_raw.jpg",
        "global_rating_score": 3.62
    },
    {
        "beer_name": "Delirium Red",
        "brewery_name": "Delirium - Huyghe Brewery",
        "beer_type": "Fruit Beer",
        "beer_abv": "8",
        "beer_ibu": "10",
        "rating_score": "4.5",
        "beer_url": "https://untappd.com/beer/33040",
        "brewery_url": "https://untappd.com/brewery/285",
        "brewery_country": "Belgium",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2015_10_10/29607fb0ed3524e5e61a2a1243b26c1e_raw.jpg",
        "global_rating_score": 3.58
    },
    {
        "beer_name": "Thunderstruck Coffee Porter",
        "brewery_name": "Highland Brewing Company",
        "beer_type": "Porter - Coffee",
        "beer_abv": "5.8",
        "beer_ibu": "26",
        "rating_score": "4.5",
        "beer_url": "https://untappd.com/beer/69665",
        "brewery_url": "https://untappd.com/brewery/658",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2016_01_01/45e0abb26b50711932acdffb856744f4_raw.jpg",
        "global_rating_score": 3.82
    },
    {
        "beer_name": "Mexican Spring",
        "brewery_name": "515 Brewing Company",
        "beer_type": "Wheat Beer - American Pale Wheat",
        "beer_abv": "5.8",
        "beer_ibu": "0",
        "rating_score": "4.5",
        "beer_url": "https://untappd.com/beer/340759",
        "brewery_url": "https://untappd.com/brewery/24774",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2016_05_01/56eb5f20fd8e47671043da90260e8c2c_raw.jpeg",
        "global_rating_score": 3.66
    },
    {
        "beer_name": "Cat In A Tree",
        "brewery_name": "Firetrucker Brewery",
        "beer_type": "Hard Ginger Beer",
        "beer_abv": "4",
        "beer_ibu": "0",
        "rating_score": "4.5",
        "beer_url": "https://untappd.com/beer/742249",
        "brewery_url": "https://untappd.com/brewery/126926",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2016_07_03/2e06a41f695ae0cbb49c82e37fe8d50f_raw.jpeg",
        "global_rating_score": 3.55
    },
    {
        "beer_name": "Red Stripe",
        "brewery_name": "Desnoes & Geddes",
        "beer_type": "Lager - American",
        "beer_abv": "4.7",
        "beer_ibu": "23",
        "rating_score": "4.5",
        "beer_url": "https://untappd.com/beer/3692",
        "brewery_url": "https://untappd.com/brewery/442",
        "brewery_country": "Jamaica",
        "photo_url": null,
        "global_rating_score": 3.1
    },
    {
        "beer_name": "Big Bridge IPA",
        "brewery_name": "Goat Island Brewing",
        "beer_type": "IPA - American",
        "beer_abv": "6.2",
        "beer_ibu": "50",
        "rating_score": "4.5",
        "beer_url": "https://untappd.com/beer/1647286",
        "brewery_url": "https://untappd.com/brewery/262751",
        "brewery_country": "United States",
        "photo_url": null,
        "global_rating_score": 3.73
    },
    {
        "beer_name": "Conover Cucumber Ale",
        "brewery_name": "PIVO Brewery",
        "beer_type": "Pale Ale - English",
        "beer_abv": "5.9",
        "beer_ibu": "39",
        "rating_score": "4.5",
        "beer_url": "https://untappd.com/beer/2545752",
        "brewery_url": "https://untappd.com/brewery/376019",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photos/2018_06_16/a71f1c3929486bfa102d16fb2cdacb28_raw.jpeg",
        "global_rating_score": 3.48
    },
    {
        "beer_name": "Helles Ya!",
        "brewery_name": "Funkwerks",
        "beer_type": "Lager - Helles",
        "beer_abv": "6",
        "beer_ibu": "0",
        "rating_score": "4.5",
        "beer_url": "https://untappd.com/beer/2015297",
        "brewery_url": "https://untappd.com/brewery/8946",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photos/2018_07_09/a58a0e55fdd13fdc1750844bb83d8aa9_raw.jpeg",
        "global_rating_score": 3.74
    },
    {
        "beer_name": "Sad Panda Coffee Stout",
        "brewery_name": "Horse & Dragon Brewing Company",
        "beer_type": "Stout - Coffee",
        "beer_abv": "6.8",
        "beer_ibu": "0",
        "rating_score": "4.5",
        "beer_url": "https://untappd.com/beer/660642",
        "brewery_url": "https://untappd.com/brewery/123311",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photos/2018_07_09/820afae2843a61afdb3e0ccdebdde954_raw.jpeg",
        "global_rating_score": 4.09
    },
    {
        "beer_name": "Oak Aged White Stout",
        "brewery_name": "Snowbank Brewing",
        "beer_type": "Stout - Other",
        "beer_abv": "6",
        "beer_ibu": "0",
        "rating_score": "4.5",
        "beer_url": "https://untappd.com/beer/2726965",
        "brewery_url": "https://untappd.com/brewery/150486",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photos/2018_07_09/0ccc120ac5982aa87af2123f0097820d_raw.jpeg",
        "global_rating_score": 4.29
    },
    {
        "beer_name": "Lihue Lager",
        "brewery_name": "Kauai Beer Company",
        "beer_type": "Lager - Helles",
        "beer_abv": "5",
        "beer_ibu": "18",
        "rating_score": "4.5",
        "beer_url": "https://untappd.com/beer/442148",
        "brewery_url": "https://untappd.com/brewery/76370",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photos/2023_05_27/3e09330df94bd2f8389ddbbf0f078488_raw.jpg",
        "global_rating_score": 3.53
    },
    {
        "beer_name": "Chocolate Vanilla Milk Stout",
        "brewery_name": "Ola Brew Company",
        "beer_type": "Stout - Milk / Sweet",
        "beer_abv": "8.5",
        "beer_ibu": "35",
        "rating_score": "4.5",
        "beer_url": "https://untappd.com/beer/4920627",
        "brewery_url": "https://untappd.com/brewery/365476",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photos/2023_05_30/4801c0bd7d2445931c7d65c3dd3a0625_raw.jpg",
        "global_rating_score": 4.16
    },
    {
        "beer_name": "Nelson Sauvin",
        "brewery_name": "Funkwerks",
        "beer_type": "Farmhouse Ale - Saison",
        "beer_abv": "7.5",
        "beer_ibu": "0",
        "rating_score": "4.25",
        "beer_url": "https://untappd.com/beer/345183",
        "brewery_url": "https://untappd.com/brewery/8946",
        "brewery_country": "United States",
        "photo_url": null,
        "global_rating_score": 3.83
    },
    {
        "beer_name": "Abt 12",
        "brewery_name": "Brouwerij St.Bernardus",
        "beer_type": "Belgian Quadrupel",
        "beer_abv": "10",
        "beer_ibu": "20",
        "rating_score": "4.25",
        "beer_url": "https://untappd.com/beer/6760",
        "brewery_url": "https://untappd.com/brewery/296",
        "brewery_country": "Belgium",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2015_08_03/147ae6bd27bca76c5d4e772c4a1cb2b3_raw.jpg",
        "global_rating_score": 4.01
    },
    {
        "beer_name": "Wick For Brains",
        "brewery_name": "Nebraska Brewing Company",
        "beer_type": "Pumpkin / Yam Beer",
        "beer_abv": "6.1",
        "beer_ibu": "17",
        "rating_score": "4.25",
        "beer_url": "https://untappd.com/beer/20492",
        "brewery_url": "https://untappd.com/brewery/901",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2015_09_11/29eb749b540a9d0db286d2b79492787a_raw.jpg",
        "global_rating_score": 3.55
    },
    {
        "beer_name": "Raspberry/Scottish Raspberry Alcoholic Ginger Beer",
        "brewery_name": "John Crabbie & Co",
        "beer_type": "Hard Ginger Beer",
        "beer_abv": "4",
        "beer_ibu": "0",
        "rating_score": "4.25",
        "beer_url": "https://untappd.com/beer/352759",
        "brewery_url": "https://untappd.com/brewery/5016",
        "brewery_country": "Scotland",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2015_11_29/e4f8522387044a40d3f6ef4ea1b3b60a_raw.jpg",
        "global_rating_score": 3.38
    },
    {
        "beer_name": "Jingle Java Holiday Stout",
        "brewery_name": "Bent River Brewing Company",
        "beer_type": "Stout - Coffee",
        "beer_abv": "5.4",
        "beer_ibu": "31",
        "rating_score": "4.25",
        "beer_url": "https://untappd.com/beer/107620",
        "brewery_url": "https://untappd.com/brewery/102",
        "brewery_country": "United States",
        "photo_url": null,
        "global_rating_score": 3.84
    },
    {
        "beer_name": "OJ IPA",
        "brewery_name": "515 Brewing Company",
        "beer_type": "Wheat Beer - American Pale Wheat",
        "beer_abv": "6.3",
        "beer_ibu": "34",
        "rating_score": "4.25",
        "beer_url": "https://untappd.com/beer/312062",
        "brewery_url": "https://untappd.com/brewery/24774",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2016_05_01/ad6eb84e61be43aa644ef6c0db4daa7a_raw.jpeg",
        "global_rating_score": 3.87
    },
    {
        "beer_name": "Chimay Dorée (Gold)",
        "brewery_name": "Bières de Chimay",
        "beer_type": "Belgian Enkel / Patersbier",
        "beer_abv": "4.8",
        "beer_ibu": "16",
        "rating_score": "4.25",
        "beer_url": "https://untappd.com/beer/4702",
        "brewery_url": "https://untappd.com/brewery/378",
        "brewery_country": "Belgium",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2016_05_04/6a58ed81bd347442127e1792e10078e9_raw.jpeg",
        "global_rating_score": 3.55
    },
    {
        "beer_name": "Rübæus",
        "brewery_name": "Founders Brewing Co.",
        "beer_type": "Fruit Beer",
        "beer_abv": "5.7",
        "beer_ibu": "15",
        "rating_score": "4.25",
        "beer_url": "https://untappd.com/beer/4590",
        "brewery_url": "https://untappd.com/brewery/549",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2016_05_08/c47dffbf785d356cb3f86366a5a93cd5_raw.jpeg",
        "global_rating_score": 3.73
    },
    {
        "beer_name": "Zoo Brew",
        "brewery_name": "Exile Brewing Company",
        "beer_type": "Pale Ale - American",
        "beer_abv": "5",
        "beer_ibu": "0",
        "rating_score": "4.25",
        "beer_url": "https://untappd.com/beer/1093947",
        "brewery_url": "https://untappd.com/brewery/37808",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2016_06_02/7f14d65291d63d28958fbae5940cbb02_raw.jpeg",
        "global_rating_score": 3.52
    },
    {
        "beer_name": "Cane Blade",
        "brewery_name": "Backpocket Brewing",
        "beer_type": "IPA - Imperial / Double",
        "beer_abv": "8.4",
        "beer_ibu": "82",
        "rating_score": "4.25",
        "beer_url": "https://untappd.com/beer/321637",
        "brewery_url": "https://untappd.com/brewery/14975",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2016_06_10/591da426cd9f5518cf371096473b92fc_raw.jpeg",
        "global_rating_score": 3.76
    },
    {
        "beer_name": "Honeycrisp Apple Wheat",
        "brewery_name": "Shock Top Brewing Co.",
        "beer_type": "Fruit Beer",
        "beer_abv": "5.2",
        "beer_ibu": "0",
        "rating_score": "4.25",
        "beer_url": "https://untappd.com/beer/275247",
        "brewery_url": "https://untappd.com/brewery/148017",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2016_06_11/e45e8e579f48b8bc7e2cf8bd3b39b6da_raw.jpeg",
        "global_rating_score": 3.27
    },
    {
        "beer_name": "Hopgazm",
        "brewery_name": "Rhodell Brewery",
        "beer_type": "IPA - Imperial / Double",
        "beer_abv": "9.5",
        "beer_ibu": "0",
        "rating_score": "4.25",
        "beer_url": "https://untappd.com/beer/934835",
        "brewery_url": "https://untappd.com/brewery/1887",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2016_06_11/66c8a7c01600d2606542ab2dcca26c03_raw.jpeg",
        "global_rating_score": 3.86
    },
    {
        "beer_name": "Orange Bitter Belgian Pale Ale",
        "brewery_name": "Peace Tree Brewing Company",
        "beer_type": "Belgian Strong Golden Ale",
        "beer_abv": "6",
        "beer_ibu": "0",
        "rating_score": "4.25",
        "beer_url": "https://untappd.com/beer/1619452",
        "brewery_url": "https://untappd.com/brewery/4250",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2016_06_26/2e47d855073b148968a8d0ea4017930b_raw.jpeg",
        "global_rating_score": 3.68
    },
    {
        "beer_name": "Mad Bark",
        "brewery_name": "Ciderboys Hard Cider",
        "beer_type": "Cider - Traditional / Apfelwein",
        "beer_abv": "5",
        "beer_ibu": "0",
        "rating_score": "4.25",
        "beer_url": "https://untappd.com/beer/259231",
        "brewery_url": "https://untappd.com/brewery/44552",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2016_09_05/ded464e202d1599526ee8bae7f617f6e_raw.jpeg",
        "global_rating_score": 3.91
    },
    {
        "beer_name": "Orange Vanilla Fathom",
        "brewery_name": "Ballast Point Brewing Company",
        "beer_type": "Lager - IPL (India Pale Lager)",
        "beer_abv": "7",
        "beer_ibu": "70",
        "rating_score": "4.25",
        "beer_url": "https://untappd.com/beer/995544",
        "brewery_url": "https://untappd.com/brewery/68",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2016_09_24/fb234e0f668cb15f7b3cd322eeb63950_raw.jpeg",
        "global_rating_score": 3.65
    },
    {
        "beer_name": "CocO Stout",
        "brewery_name": "West O Beer",
        "beer_type": "Stout - Milk / Sweet",
        "beer_abv": "6",
        "beer_ibu": "24",
        "rating_score": "4.25",
        "beer_url": "https://untappd.com/beer/549565",
        "brewery_url": "https://untappd.com/brewery/65918",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2016_09_24/f81ce519a1fca901f85b5183effcf0b2_raw.jpeg",
        "global_rating_score": 3.9
    },
    {
        "beer_name": "Saison Des Moines With Cranberries and Spruce Tips",
        "brewery_name": "Confluence Brewing Company",
        "beer_type": "Farmhouse Ale - Saison",
        "beer_abv": "7.1",
        "beer_ibu": "20",
        "rating_score": "4.25",
        "beer_url": "https://untappd.com/beer/1837622",
        "brewery_url": "https://untappd.com/brewery/41654",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2017_01_08/e2987c9ac19bda6451148619c38e0124_raw.jpeg",
        "global_rating_score": 3.68
    },
    {
        "beer_name": "Wolf Pup Session IPA",
        "brewery_name": "Golden Road Brewing",
        "beer_type": "IPA - Session",
        "beer_abv": "4.5",
        "beer_ibu": "50",
        "rating_score": "4.25",
        "beer_url": "https://untappd.com/beer/945458",
        "brewery_url": "https://untappd.com/brewery/16302",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2017_02_11/691609e3067b968e4dea93e12b13cf74_raw.jpeg",
        "global_rating_score": 3.62
    },
    {
        "beer_name": "Main Squeeze",
        "brewery_name": "Lake Time Brewery",
        "beer_type": "Fruit Beer",
        "beer_abv": "4.2",
        "beer_ibu": "13",
        "rating_score": "4.25",
        "beer_url": "https://untappd.com/beer/1096854",
        "brewery_url": "https://untappd.com/brewery/69372",
        "brewery_country": "United States",
        "photo_url": null,
        "global_rating_score": 3.49
    },
    {
        "beer_name": "Mocha Porter",
        "brewery_name": "Rogue Ales",
        "beer_type": "Porter - Coffee",
        "beer_abv": "5.6",
        "beer_ibu": "47",
        "rating_score": "4.25",
        "beer_url": "https://untappd.com/beer/6863",
        "brewery_url": "https://untappd.com/brewery/4565",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2017_11_11/ce34816162ebdef85433b93b44dbfeaa_raw.jpeg",
        "global_rating_score": 3.59
    },
    {
        "beer_name": "Tropical Fusion",
        "brewery_name": "Sea Dog Brewing Company",
        "beer_type": "Wheat Beer - Hefeweizen",
        "beer_abv": "4.6",
        "beer_ibu": "0",
        "rating_score": "4.25",
        "beer_url": "https://untappd.com/beer/1750681",
        "brewery_url": "https://untappd.com/brewery/1125",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2017_12_24/ae859600342a14d826b1c93662d49b0c_raw.jpeg",
        "global_rating_score": 3.58
    },
    {
        "beer_name": "Long Route Peanut Butter Porter",
        "brewery_name": "Empyrean Brewing Co.",
        "beer_type": "Porter - American",
        "beer_abv": "5",
        "beer_ibu": "20",
        "rating_score": "4.25",
        "beer_url": "https://untappd.com/beer/1712470",
        "brewery_url": "https://untappd.com/brewery/501",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photos/2018_03_09/55960abaa8cf794fa250976cfc1e0c2e_raw.jpeg",
        "global_rating_score": 3.84
    },
    {
        "beer_name": "Mural Watermelon Lime",
        "brewery_name": "New Belgium Brewing Company",
        "beer_type": "Fruit Beer",
        "beer_abv": "4",
        "beer_ibu": "11",
        "rating_score": "4.25",
        "beer_url": "https://untappd.com/beer/2550077",
        "brewery_url": "https://untappd.com/brewery/905",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photos/2018_07_08/223233708a1f970abbcafbb7410e8894_raw.jpeg",
        "global_rating_score": 3.36
    },
    {
        "beer_name": "Tropic King",
        "brewery_name": "Funkwerks",
        "beer_type": "Farmhouse Ale - Saison",
        "beer_abv": "8",
        "beer_ibu": "30",
        "rating_score": "4.25",
        "beer_url": "https://untappd.com/beer/92984",
        "brewery_url": "https://untappd.com/brewery/8946",
        "brewery_country": "United States",
        "photo_url": null,
        "global_rating_score": 3.77
    },
    {
        "beer_name": "Raspberry Provincial",
        "brewery_name": "Funkwerks",
        "beer_type": "Sour - Other",
        "beer_abv": "4.2",
        "beer_ibu": "0",
        "rating_score": "4.25",
        "beer_url": "https://untappd.com/beer/582210",
        "brewery_url": "https://untappd.com/brewery/8946",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photos/2018_07_09/fd9b69a7fc06b87da260227639c73eff_raw.jpeg",
        "global_rating_score": 3.77
    },
    {
        "beer_name": "Cranknbrew",
        "brewery_name": "Snowbank Brewing",
        "beer_type": "Pale Ale - American",
        "beer_abv": "5.5",
        "beer_ibu": "40",
        "rating_score": "4.25",
        "beer_url": "https://untappd.com/beer/1030548",
        "brewery_url": "https://untappd.com/brewery/150486",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photos/2018_07_09/1ddfe30d507985f6894d36d80f107165_raw.jpeg",
        "global_rating_score": 3.94
    },
    {
        "beer_name": "Drunken Pumpkin",
        "brewery_name": "East Grove Farms",
        "beer_type": "Mead - Other",
        "beer_abv": "10",
        "beer_ibu": "0",
        "rating_score": "4.25",
        "beer_url": "https://untappd.com/beer/2848164",
        "brewery_url": "https://untappd.com/brewery/229496",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photos/2018_09_26/498bf9fc0cf9e396af6af979f1443283_raw.jpeg",
        "global_rating_score": 3.85
    },
    {
        "beer_name": "Maple Syzzurp",
        "brewery_name": "Boulder Beer",
        "beer_type": "Porter - Other",
        "beer_abv": "5.9",
        "beer_ibu": "0",
        "rating_score": "4.25",
        "beer_url": "https://untappd.com/beer/2917226",
        "brewery_url": "https://untappd.com/brewery/160",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photos/2018_11_25/655f8b7251fa5512b1d2d061572f0761_raw.jpg",
        "global_rating_score": 3.89
    },
    {
        "beer_name": "Hanging Lake Light Lager",
        "brewery_name": "Glenwood Canyon Brewing Company",
        "beer_type": "Lager - American Light",
        "beer_abv": "5",
        "beer_ibu": "12",
        "rating_score": "4.25",
        "beer_url": "https://untappd.com/beer/3947726",
        "brewery_url": "https://untappd.com/brewery/577",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photos/2019_03_21/7fbe3560cd45363477011c8ced309768_raw.jpg",
        "global_rating_score": 3.58
    },
    {
        "beer_name": "Boone Valley IPA",
        "brewery_name": "Boone Valley Brewing Company",
        "beer_type": "IPA - American",
        "beer_abv": "6.8",
        "beer_ibu": "73",
        "rating_score": "4.25",
        "beer_url": "https://untappd.com/beer/1080040",
        "brewery_url": "https://untappd.com/brewery/27348",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photos/2019_07_20/2b7b1884c51b7bf1f75211ab826c4c64_raw.jpg",
        "global_rating_score": 3.56
    },
    {
        "beer_name": "They See Me Munchen",
        "brewery_name": "Four Day Ray Brewing",
        "beer_type": "Lager - Munich Dunkel",
        "beer_abv": "5.2",
        "beer_ibu": "25",
        "rating_score": "4.25",
        "beer_url": "https://untappd.com/beer/4030279",
        "brewery_url": "https://untappd.com/brewery/202106",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photos/2021_06_15/ecc5090ebe2694d52a918669558a3a3c_raw.jpg",
        "global_rating_score": 3.6
    },
    {
        "beer_name": "Kentucky Tangerine Cream Ale",
        "brewery_name": "Lexington Brewing & Distilling Co.",
        "beer_type": "Cream Ale",
        "beer_abv": "5.5",
        "beer_ibu": "0",
        "rating_score": "4.25",
        "beer_url": "https://untappd.com/beer/4703945",
        "brewery_url": "https://untappd.com/brewery/28",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photos/2022_04_30/ca8cfe61abbdc7824b010ad5d65534fa_raw.jpg",
        "global_rating_score": 3.79
    },
    {
        "beer_name": "Gravel & Heels",
        "brewery_name": "Bhramari Brewing Company",
        "beer_type": "Sour - Fruited Berliner Weisse",
        "beer_abv": "6.4",
        "beer_ibu": "0",
        "rating_score": "4.25",
        "beer_url": "https://untappd.com/beer/4928434",
        "brewery_url": "https://untappd.com/brewery/224530",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photos/2022_07_31/7a99b794c55e17a1011a840848c14a6a_raw.jpg",
        "global_rating_score": 3.84
    },
    {
        "beer_name": "Weiner Bever",
        "brewery_name": "515 Brewing Company",
        "beer_type": "Wheat Beer - American Pale Wheat",
        "beer_abv": "4.8",
        "beer_ibu": "32",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/601369",
        "brewery_url": "https://untappd.com/brewery/24774",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2014_09_06/8053672329048a79785f99ca1baa19f9_raw.jpg",
        "global_rating_score": 3.55
    },
    {
        "beer_name": "Castaway Porter",
        "brewery_name": "515 Brewing Company",
        "beer_type": "Porter - American",
        "beer_abv": "6.1",
        "beer_ibu": "29",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/361159",
        "brewery_url": "https://untappd.com/brewery/24774",
        "brewery_country": "United States",
        "photo_url": null,
        "global_rating_score": 3.91
    },
    {
        "beer_name": "Wooden Nickel",
        "brewery_name": "Backpocket Brewing",
        "beer_type": "Bock - Single / Traditional",
        "beer_abv": "5.7",
        "beer_ibu": "25",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/109945",
        "brewery_url": "https://untappd.com/brewery/14975",
        "brewery_country": "United States",
        "photo_url": null,
        "global_rating_score": 3.48
    },
    {
        "beer_name": "Singha",
        "brewery_name": "Boon Rawd Brewery",
        "beer_type": "Lager - Pale",
        "beer_abv": "5",
        "beer_ibu": "20",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/6344",
        "brewery_url": "https://untappd.com/brewery/152",
        "brewery_country": "Thailand",
        "photo_url": null,
        "global_rating_score": 3.05
    },
    {
        "beer_name": "OctoberFest",
        "brewery_name": "Samuel Adams",
        "beer_type": "Märzen",
        "beer_abv": "5.3",
        "beer_ibu": "16",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/3942",
        "brewery_url": "https://untappd.com/brewery/157",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2014_09_26/ebacf824b22dba417229a67f1b7cb905_raw.jpg",
        "global_rating_score": 3.61
    },
    {
        "beer_name": "Harvest Pumpkin Ale",
        "brewery_name": "Samuel Adams",
        "beer_type": "Pumpkin / Yam Beer",
        "beer_abv": "5.7",
        "beer_ibu": "0",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/6278",
        "brewery_url": "https://untappd.com/brewery/157",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2014_09_26/fb5e05dd93a42a7f0eb02962c4cdd298_raw.jpg",
        "global_rating_score": 3.32
    },
    {
        "beer_name": "Newcastle Brown Ale",
        "brewery_name": "John Smith's",
        "beer_type": "Brown Ale - English",
        "beer_abv": "4.7",
        "beer_ibu": "18",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/5856",
        "brewery_url": "https://untappd.com/brewery/1816",
        "brewery_country": "England",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2014_09_27/078bf8d324f3827035de0e5dd27c6f93_raw.jpg",
        "global_rating_score": 3.39
    },
    {
        "beer_name": "Hofbräu Oktoberfestbier",
        "brewery_name": "Staatliches Hofbräuhaus München",
        "beer_type": "Festbier",
        "beer_abv": "6.3",
        "beer_ibu": "23",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/40139",
        "brewery_url": "https://untappd.com/brewery/5566",
        "brewery_country": "Germany",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2014_09_27/f3f9b6f95c236dbab593a200ffa40d16_raw.jpg",
        "global_rating_score": 3.6
    },
    {
        "beer_name": "Oktoberfest Märzen / Original Münchner Märzen",
        "brewery_name": "Paulaner Brauerei",
        "beer_type": "Märzen",
        "beer_abv": "5.8",
        "beer_ibu": "20",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/15180",
        "brewery_url": "https://untappd.com/brewery/972",
        "brewery_country": "Germany",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2014_09_27/98468133298a59a9fad478583cedd6e2_raw.jpg",
        "global_rating_score": 3.68
    },
    {
        "beer_name": "Fresh Squeezed",
        "brewery_name": "Deschutes Brewery",
        "beer_type": "IPA - American",
        "beer_abv": "6.4",
        "beer_ibu": "60",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/59622",
        "brewery_url": "https://untappd.com/brewery/441",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2014_10_03/8f9bdc9e466ebae5021dd68e792271b2_raw.jpg",
        "global_rating_score": 3.91
    },
    {
        "beer_name": "Inversion IPA",
        "brewery_name": "Deschutes Brewery",
        "beer_type": "IPA - American",
        "beer_abv": "6.3",
        "beer_ibu": "65",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/423",
        "brewery_url": "https://untappd.com/brewery/441",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2014_10_03/184e4b7684c939cc674b3dee33da6ba9_raw.jpg",
        "global_rating_score": 3.62
    },
    {
        "beer_name": "Creamy Dark",
        "brewery_name": "Jacob Leinenkugel Brewing Company",
        "beer_type": "Lager - Dark",
        "beer_abv": "4.9",
        "beer_ibu": "20",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/232",
        "brewery_url": "https://untappd.com/brewery/708",
        "brewery_country": "United States",
        "photo_url": null,
        "global_rating_score": 3.38
    },
    {
        "beer_name": "G.G.",
        "brewery_name": "Exile Brewing Company",
        "beer_type": "Lager - Munich Dunkel",
        "beer_abv": "5",
        "beer_ibu": "18",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/244191",
        "brewery_url": "https://untappd.com/brewery/37808",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2014_10_25/65121ac0bbce80501af0d83f0a894ca8_raw.jpg",
        "global_rating_score": 3.69
    },
    {
        "beer_name": "Shockolate Wheat",
        "brewery_name": "Shock Top Brewing Co.",
        "beer_type": "Winter Ale",
        "beer_abv": "4.3",
        "beer_ibu": "0",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/850108",
        "brewery_url": "https://untappd.com/brewery/148017",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2014_10_26/1d1e0b97c040dce4a63ae386c5184f75_raw.jpg",
        "global_rating_score": 3.36
    },
    {
        "beer_name": "Tangerine Wheat",
        "brewery_name": "Lost Coast Brewery",
        "beer_type": "Wheat Beer - Other",
        "beer_abv": "5.2",
        "beer_ibu": "15",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/6410",
        "brewery_url": "https://untappd.com/brewery/803",
        "brewery_country": "United States",
        "photo_url": null,
        "global_rating_score": 3.68
    },
    {
        "beer_name": "Edmund Fitzgerald Porter",
        "brewery_name": "Great Lakes Brewing Company",
        "beer_type": "Porter - American",
        "beer_abv": "6",
        "beer_ibu": "37",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/12049",
        "brewery_url": "https://untappd.com/brewery/3125",
        "brewery_country": "United States",
        "photo_url": null,
        "global_rating_score": 3.79
    },
    {
        "beer_name": "Big Eddy Cherry Doppelschwarz",
        "brewery_name": "Jacob Leinenkugel Brewing Company",
        "beer_type": "Schwarzbier",
        "beer_abv": "8.5",
        "beer_ibu": "27",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/839169",
        "brewery_url": "https://untappd.com/brewery/708",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2014_12_07/75ec6e61ef2219b88e1c6477e7a849a4_raw.jpg",
        "global_rating_score": 3.6
    },
    {
        "beer_name": "Big Shot Espresso Stout",
        "brewery_name": "Twisted Pine Brewing Company",
        "beer_type": "Stout - Coffee",
        "beer_abv": "6",
        "beer_ibu": "30",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/5504",
        "brewery_url": "https://untappd.com/brewery/1297",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2014_12_27/457fa45e70051ba4d42d1574a2b2f6b8_raw.jpg",
        "global_rating_score": 3.75
    },
    {
        "beer_name": "Dunkel",
        "brewery_name": "Warsteiner",
        "beer_type": "Lager - Munich Dunkel",
        "beer_abv": "4.8",
        "beer_ibu": "28",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/11936",
        "brewery_url": "https://untappd.com/brewery/1337",
        "brewery_country": "Germany",
        "photo_url": null,
        "global_rating_score": 3.64
    },
    {
        "beer_name": "Stone Ruination IPA",
        "brewery_name": "Stone Brewing",
        "beer_type": "IPA - Imperial / Double",
        "beer_abv": "8.2",
        "beer_ibu": "100",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/1382",
        "brewery_url": "https://untappd.com/brewery/1204",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2015_01_07/84a00ffcc4ba49a1d06a62cce438b7e5_raw.jpg",
        "global_rating_score": 4.03
    },
    {
        "beer_name": "Sidekick",
        "brewery_name": "Two Brothers Brewing Company",
        "beer_type": "Pale Ale - XPA (Extra Pale)",
        "beer_abv": "5.1",
        "beer_ibu": "36",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/323568",
        "brewery_url": "https://untappd.com/brewery/1298",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2015_01_24/c80cecae2acfc3914c28f55453c948ed_raw.jpg",
        "global_rating_score": 3.6
    },
    {
        "beer_name": "Domaine DuPage",
        "brewery_name": "Two Brothers Brewing Company",
        "beer_type": "Farmhouse Ale - Bière de Garde",
        "beer_abv": "6.2",
        "beer_ibu": "24",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/8820",
        "brewery_url": "https://untappd.com/brewery/1298",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2015_01_24/d552f010714931fc345eeb8817c7783b_raw.jpg",
        "global_rating_score": 3.6
    },
    {
        "beer_name": "Shiner Birthday Beer 106",
        "brewery_name": "Spoetzl Brewery",
        "beer_type": "Stout - Milk / Sweet",
        "beer_abv": "5",
        "beer_ibu": "20",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/862169",
        "brewery_url": "https://untappd.com/brewery/1179",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2015_01_31/e0b2249e83109f06ea4c3090de54e387_raw.jpg",
        "global_rating_score": 3.57
    },
    {
        "beer_name": "Coors Light Summer Brew",
        "brewery_name": "Coors Brewing Company",
        "beer_type": "Shandy / Radler",
        "beer_abv": "3.9",
        "beer_ibu": "0",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/629197",
        "brewery_url": "https://untappd.com/brewery/399",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2015_05_03/9d6a9ca723c53f07432f36ca371855cc_raw.jpg",
        "global_rating_score": 2.71
    },
    {
        "beer_name": "Schöfferhofer Grapefruit",
        "brewery_name": "Radeberger Gruppe",
        "beer_type": "Shandy / Radler",
        "beer_abv": "2.5",
        "beer_ibu": "0",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/21857",
        "brewery_url": "https://untappd.com/brewery/4936",
        "brewery_country": "Germany",
        "photo_url": null,
        "global_rating_score": 3.59
    },
    {
        "beer_name": "Aris",
        "brewery_name": "Olympic Brewery",
        "beer_type": "Lager - Pale",
        "beer_abv": "5",
        "beer_ibu": "0",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/12387",
        "brewery_url": "https://untappd.com/brewery/3539",
        "brewery_country": "Greece",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2015_06_06/a3da6891cce07e483819b1abc26e1813_raw.jpg",
        "global_rating_score": 3.03
    },
    {
        "beer_name": "Orange Scorpion",
        "brewery_name": "CIB Brewery",
        "beer_type": "Spiced / Herbed Beer",
        "beer_abv": "7.2",
        "beer_ibu": "0",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/137749",
        "brewery_url": "https://untappd.com/brewery/23343",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2015_06_20/fc91d3a82bd3af31f69936c78f3d283c_raw.jpg",
        "global_rating_score": 3.19
    },
    {
        "beer_name": "Banana Bread Beer",
        "brewery_name": "Eagle Brewery (formerly Charles Wells)",
        "beer_type": "Fruit Beer",
        "beer_abv": "5.2",
        "beer_ibu": "18",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/5839",
        "brewery_url": "https://untappd.com/brewery/1348",
        "brewery_country": "England",
        "photo_url": null,
        "global_rating_score": 3.55
    },
    {
        "beer_name": "Sticky Toffee Pudding Ale",
        "brewery_name": "Eagle Brewery (formerly Charles Wells)",
        "beer_type": "Brown Ale - English",
        "beer_abv": "5",
        "beer_ibu": "0",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/418474",
        "brewery_url": "https://untappd.com/brewery/1348",
        "brewery_country": "England",
        "photo_url": null,
        "global_rating_score": 3.45
    },
    {
        "beer_name": "The Last Hope Double IPA",
        "brewery_name": "75th Street Brewery",
        "beer_type": "IPA - Imperial / Double",
        "beer_abv": "10.2",
        "beer_ibu": "110",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/1084886",
        "brewery_url": "https://untappd.com/brewery/2506",
        "brewery_country": "United States",
        "photo_url": null,
        "global_rating_score": 3.73
    },
    {
        "beer_name": "Delta 88 IPA",
        "brewery_name": "The Big Rip Brewing Company",
        "beer_type": "IPA - American",
        "beer_abv": "6.2",
        "beer_ibu": "88",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/452517",
        "brewery_url": "https://untappd.com/brewery/64940",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2015_07_08/748306750bc7fb407fe6cfbdd02c35ff_raw.jpg",
        "global_rating_score": 3.43
    },
    {
        "beer_name": "Aisle 12 West Coast IPA",
        "brewery_name": "The Big Rip Brewing Company",
        "beer_type": "IPA - American",
        "beer_abv": "7.7",
        "beer_ibu": "94",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/617461",
        "brewery_url": "https://untappd.com/brewery/64940",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2015_07_08/0b700fb847584725a2221d31c72ec72d_raw.jpg",
        "global_rating_score": 3.57
    },
    {
        "beer_name": "Lucille",
        "brewery_name": "Exile Brewing Company",
        "beer_type": "IPA - Black / Cascadian Dark Ale",
        "beer_abv": "6.4",
        "beer_ibu": "67",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/1096263",
        "brewery_url": "https://untappd.com/brewery/37808",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2015_07_10/8476379e757122b2765b28ac68b253ea_raw.jpg",
        "global_rating_score": 3.72
    },
    {
        "beer_name": "Lucha Libre",
        "brewery_name": "Exile Brewing Company",
        "beer_type": "Lager - Pale",
        "beer_abv": "6.1",
        "beer_ibu": "31",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/1027476",
        "brewery_url": "https://untappd.com/brewery/37808",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2015_07_10/3c6bf54d4d0f4b4389ab296735fa6ae0_raw.jpg",
        "global_rating_score": 3.59
    },
    {
        "beer_name": "Somersault",
        "brewery_name": "New Belgium Brewing Company",
        "beer_type": "Blonde Ale",
        "beer_abv": "5.2",
        "beer_ibu": "25",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/43684",
        "brewery_url": "https://untappd.com/brewery/905",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2015_07_16/7f50eff262f64b3faa72026b219b80cd_raw.jpg",
        "global_rating_score": 3.4
    },
    {
        "beer_name": "Deceit",
        "brewery_name": "Funkwerks",
        "beer_type": "Belgian Strong Golden Ale",
        "beer_abv": "8.5",
        "beer_ibu": "20",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/216409",
        "brewery_url": "https://untappd.com/brewery/8946",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2015_07_16/b5f3d8d87beb94b34c8605be3d5ed2b8_raw.jpg",
        "global_rating_score": 3.82
    },
    {
        "beer_name": "School's Out Farmhouse Ale",
        "brewery_name": "Funkwerks",
        "beer_type": "Farmhouse Ale - Saison",
        "beer_abv": "6.3",
        "beer_ibu": "0",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/1148615",
        "brewery_url": "https://untappd.com/brewery/8946",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2015_07_17/6a3db94c594b010aec073e39f8f5ddde_raw.jpg",
        "global_rating_score": 3.75
    },
    {
        "beer_name": "Pre-2016 DPA (old version)",
        "brewery_name": "Great Divide Brewing Company",
        "beer_type": "Pale Ale - English",
        "beer_abv": "5.4",
        "beer_ibu": "40",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/215",
        "brewery_url": "https://untappd.com/brewery/604",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2015_07_30/ea28c66a4e21701f00aa2c0538f37290_raw.jpg",
        "global_rating_score": 3.51
    },
    {
        "beer_name": "Vanilla Porter",
        "brewery_name": "Breckenridge Brewery",
        "beer_type": "Porter - Other",
        "beer_abv": "5.4",
        "beer_ibu": "16",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/8063",
        "brewery_url": "https://untappd.com/brewery/236",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2015_07_30/f4fa003732f994b04fc85ec627c392f4_raw.jpg",
        "global_rating_score": 3.77
    },
    {
        "beer_name": "Avalanche Amber Ale",
        "brewery_name": "Breckenridge Brewery",
        "beer_type": "Red Ale - American Amber / Red",
        "beer_abv": "5",
        "beer_ibu": "19",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/3721",
        "brewery_url": "https://untappd.com/brewery/236",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2015_07_30/622d6be8241eb7a2d5168233c394928a_raw.jpg",
        "global_rating_score": 3.51
    },
    {
        "beer_name": "3-Way",
        "brewery_name": "515 Brewing Company",
        "beer_type": "IPA - Belgian",
        "beer_abv": "7.9",
        "beer_ibu": "0",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/1128877",
        "brewery_url": "https://untappd.com/brewery/24774",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2015_09_05/cc58c6b062384454d281cee2433019b7_raw.jpg",
        "global_rating_score": 3.77
    },
    {
        "beer_name": "IPA (2011 - Oct 2016)",
        "brewery_name": "Nebraska Brewing Company",
        "beer_type": "IPA - American",
        "beer_abv": "6.9",
        "beer_ibu": "72",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/60685",
        "brewery_url": "https://untappd.com/brewery/901",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2015_09_11/46cfca00abaac5ce324b5c31e2db4048_raw.jpg",
        "global_rating_score": 3.66
    },
    {
        "beer_name": "Organic Chocolate Stout",
        "brewery_name": "Samuel Smith",
        "beer_type": "Stout - English",
        "beer_abv": "5",
        "beer_ibu": "28",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/219082",
        "brewery_url": "https://untappd.com/brewery/1099",
        "brewery_country": "England",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2015_09_19/55bb9601451f9934aa63d649e88146d5_raw.jpg",
        "global_rating_score": 4.05
    },
    {
        "beer_name": "La Chouffe Blond",
        "brewery_name": "Brasserie d'Achouffe",
        "beer_type": "Belgian Strong Golden Ale",
        "beer_abv": "8",
        "beer_ibu": "20",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/52593",
        "brewery_url": "https://untappd.com/brewery/11866",
        "brewery_country": "Belgium",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2015_10_10/b249e80a92e79d5e76cf3013db7c1c19_raw.jpg",
        "global_rating_score": 3.82
    },
    {
        "beer_name": "Trappistes Rochefort 8",
        "brewery_name": "Abbaye Notre-Dame de Saint-Rémy",
        "beer_type": "Belgian Strong Dark Ale",
        "beer_abv": "9.2",
        "beer_ibu": "22",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/11473",
        "brewery_url": "https://untappd.com/brewery/1650",
        "brewery_country": "Belgium",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2015_10_10/c2be6703f45fec5a8b865fffe8cb920e_raw.jpg",
        "global_rating_score": 3.85
    },
    {
        "beer_name": "Trois Pistoles",
        "brewery_name": "Unibroue",
        "beer_type": "Belgian Strong Dark Ale",
        "beer_abv": "9",
        "beer_ibu": "16",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/4045",
        "brewery_url": "https://untappd.com/brewery/1307",
        "brewery_country": "Canada",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2015_10_10/2f8bf1b94923fa68541571d83a873a6f_raw.jpg",
        "global_rating_score": 3.86
    },
    {
        "beer_name": "Angelicus Belgian Double Pale Ale",
        "brewery_name": "Firetrucker Brewery",
        "beer_type": "Belgian Strong Golden Ale",
        "beer_abv": "11",
        "beer_ibu": "60",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/1243575",
        "brewery_url": "https://untappd.com/brewery/126926",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2015_10_21/926a628ca35767a259a1f362c726573a_raw.jpg",
        "global_rating_score": 3.68
    },
    {
        "beer_name": "Traditional Lager",
        "brewery_name": "Yuengling Brewery",
        "beer_type": "Lager - American Amber / Red",
        "beer_abv": "4.5",
        "beer_ibu": "12",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/16649",
        "brewery_url": "https://untappd.com/brewery/1976",
        "brewery_country": "United States",
        "photo_url": null,
        "global_rating_score": 3.49
    },
    {
        "beer_name": "Ogeechee Riverkeeper Ale",
        "brewery_name": "Moon River Brewing Company",
        "beer_type": "Pale Ale - American",
        "beer_abv": "5.3",
        "beer_ibu": "0",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/1303329",
        "brewery_url": "https://untappd.com/brewery/882",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2015_12_31/76a7f32c2279960606a333da0ad652c8_raw.jpg",
        "global_rating_score": 3.43
    },
    {
        "beer_name": "Saw-Whet Saison",
        "brewery_name": "Highland Brewing Company",
        "beer_type": "Farmhouse Ale - Saison",
        "beer_abv": "6",
        "beer_ibu": "50",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/1392002",
        "brewery_url": "https://untappd.com/brewery/658",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2016_01_01/fdeceb0e7e01dc4d95bf7c8e68924f0b_raw.jpg",
        "global_rating_score": 3.61
    },
    {
        "beer_name": "Black Mocha Stout",
        "brewery_name": "Highland Brewing Company",
        "beer_type": "Stout - American",
        "beer_abv": "5",
        "beer_ibu": "30",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/4467",
        "brewery_url": "https://untappd.com/brewery/658",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2016_01_01/4a973f4bf4acf2f92fae490d6a01e8d2_raw.jpg",
        "global_rating_score": 3.7
    },
    {
        "beer_name": "West Sixth IPA",
        "brewery_name": "West Sixth Brewing",
        "beer_type": "IPA - American",
        "beer_abv": "7",
        "beer_ibu": "80",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/131260",
        "brewery_url": "https://untappd.com/brewery/23986",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2016_01_02/395cf2b48a4274d830509e7e69ddde3b_raw.jpg",
        "global_rating_score": 3.7
    },
    {
        "beer_name": "Doubleplusgood",
        "brewery_name": "MadTree Brewing Company",
        "beer_type": "IPA - American",
        "beer_abv": "7.9",
        "beer_ibu": "77",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/1101430",
        "brewery_url": "https://untappd.com/brewery/16735",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2016_01_02/43c52152fac3ad4c2e4a837b9f1f6c0c_raw.jpg",
        "global_rating_score": 3.9
    },
    {
        "beer_name": "Grapefruit Sculpin",
        "brewery_name": "Ballast Point Brewing Company",
        "beer_type": "IPA - Fruited",
        "beer_abv": "7",
        "beer_ibu": "70",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/285658",
        "brewery_url": "https://untappd.com/brewery/68",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2016_05_07/80a9afcf1f8ee6cbcad30466cf3a1a5a_raw.jpeg",
        "global_rating_score": 3.96
    },
    {
        "beer_name": "Blood On the Blade",
        "brewery_name": "Backpocket Brewing",
        "beer_type": "IPA - Red",
        "beer_abv": "6.5",
        "beer_ibu": "70",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/870799",
        "brewery_url": "https://untappd.com/brewery/14975",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2016_06_10/b5589bd8f0191db1c25b9512de97c6f6_raw.jpeg",
        "global_rating_score": 3.69
    },
    {
        "beer_name": "Nut Brown Ale",
        "brewery_name": "Rhodell Brewery",
        "beer_type": "Brown Ale - Belgian",
        "beer_abv": "5",
        "beer_ibu": "0",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/1584955",
        "brewery_url": "https://untappd.com/brewery/1887",
        "brewery_country": "United States",
        "photo_url": null,
        "global_rating_score": 3.82
    },
    {
        "beer_name": "Ace Perry Cider",
        "brewery_name": "Ace Cider (The California Cider Company)",
        "beer_type": "Cider - Perry / Poiré",
        "beer_abv": "5",
        "beer_ibu": "0",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/3603",
        "brewery_url": "https://untappd.com/brewery/331",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2016_06_12/35bd4b04c1dc840e1249c211998da927_raw.jpeg",
        "global_rating_score": 3.69
    },
    {
        "beer_name": "Diablo Red",
        "brewery_name": "Twisted Vine Brewery (Iowa)",
        "beer_type": "IPA - Red",
        "beer_abv": "6.1",
        "beer_ibu": "45",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/898590",
        "brewery_url": "https://untappd.com/brewery/23145",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2016_07_03/e65f05fd758dc14dc614a2fc3e25c349_raw.jpeg",
        "global_rating_score": 3.56
    },
    {
        "beer_name": "Madison County Saison",
        "brewery_name": "Twisted Vine Brewery (Iowa)",
        "beer_type": "Farmhouse Ale - Saison",
        "beer_abv": "6.8",
        "beer_ibu": "28",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/1569733",
        "brewery_url": "https://untappd.com/brewery/23145",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2016_07_03/9d57a2aa5a81a3605c6a3f745eb5e3cd_raw.jpeg",
        "global_rating_score": 3.64
    },
    {
        "beer_name": "Früli Strawberry Beer",
        "brewery_name": "Delirium - Huyghe Brewery",
        "beer_type": "Fruit Beer",
        "beer_abv": "4.1",
        "beer_ibu": "8",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/6314",
        "brewery_url": "https://untappd.com/brewery/285",
        "brewery_country": "Belgium",
        "photo_url": null,
        "global_rating_score": 3.68
    },
    {
        "beer_name": "Stiegl-Radler Grapefruit ",
        "brewery_name": "Stieglbrauerei zu Salzburg",
        "beer_type": "Shandy / Radler",
        "beer_abv": "2",
        "beer_ibu": "8",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/51016",
        "brewery_url": "https://untappd.com/brewery/1202",
        "brewery_country": "Austria",
        "photo_url": null,
        "global_rating_score": 3.79
    },
    {
        "beer_name": "ReUnion Lager",
        "brewery_name": "ReUnion Brewery",
        "beer_type": "Lager - Helles",
        "beer_abv": "5",
        "beer_ibu": "25",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/1321703",
        "brewery_url": "https://untappd.com/brewery/237075",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2016_08_05/fedff0753408b8ea111e0e68f4a81de3_raw.jpeg",
        "global_rating_score": 3.41
    },
    {
        "beer_name": "ReUnion IPA",
        "brewery_name": "ReUnion Brewery",
        "beer_type": "IPA - American",
        "beer_abv": "7",
        "beer_ibu": "60",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/1315640",
        "brewery_url": "https://untappd.com/brewery/237075",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2016_08_05/1eaf493142ca79cface15408f451ed72_raw.jpeg",
        "global_rating_score": 3.67
    },
    {
        "beer_name": "Cat's Away IPA",
        "brewery_name": "Trouble Brewing Company",
        "beer_type": "IPA - American",
        "beer_abv": "5.9",
        "beer_ibu": "0",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/1472903",
        "brewery_url": "https://untappd.com/brewery/257578",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2016_11_19/587a1c5c9060e23c8e0a0cffa9bc37d6_raw.jpeg",
        "global_rating_score": 3.23
    },
    {
        "beer_name": "Drumroll",
        "brewery_name": "Odell Brewing Co.",
        "beer_type": "Pale Ale - New England / Hazy",
        "beer_abv": "5.3",
        "beer_ibu": "42",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/1600793",
        "brewery_url": "https://untappd.com/brewery/7296",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2016_11_20/610a1e8944868cb0e4184b2dc2b42644_raw.jpeg",
        "global_rating_score": 3.75
    },
    {
        "beer_name": "Axe Man",
        "brewery_name": "Surly Brewing Company",
        "beer_type": "IPA - American",
        "beer_abv": "7.2",
        "beer_ibu": "65",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/882221",
        "brewery_url": "https://untappd.com/brewery/2609",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2016_11_20/d30c75254cad6bbbbb793eff07b12d49_raw.jpeg",
        "global_rating_score": 4.13
    },
    {
        "beer_name": "Tangerine Soul Style IPA",
        "brewery_name": "Green Flash Brewing Company",
        "beer_type": "IPA - Fruited",
        "beer_abv": "6.5",
        "beer_ibu": "75",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/998286",
        "brewery_url": "https://untappd.com/brewery/609",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2017_01_08/715a7f1dcf3ca62e160d474675d90e3c_raw.jpeg",
        "global_rating_score": 3.67
    },
    {
        "beer_name": "Cucumber Farmhouse",
        "brewery_name": "Uinta Brewing Company",
        "beer_type": "Farmhouse Ale - Saison",
        "beer_abv": "6.2",
        "beer_ibu": "19",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/1507108",
        "brewery_url": "https://untappd.com/brewery/1304",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2017_09_10/dac5d89dbf50eb05582f5bc52818255e_raw.jpeg",
        "global_rating_score": 3.58
    },
    {
        "beer_name": "Maudite",
        "brewery_name": "Unibroue",
        "beer_type": "Belgian Dubbel",
        "beer_abv": "8",
        "beer_ibu": "22",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/4044",
        "brewery_url": "https://untappd.com/brewery/1307",
        "brewery_country": "Canada",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2017_09_23/e546e5b3858025d3297507cdf405397b_raw.jpeg",
        "global_rating_score": 3.78
    },
    {
        "beer_name": "Lily Ale",
        "brewery_name": "Bur Oak Brewing Company",
        "beer_type": "Fruit Beer",
        "beer_abv": "4.8",
        "beer_ibu": "15",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/782316",
        "brewery_url": "https://untappd.com/brewery/33928",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2017_10_01/00023baef7f03604ed1655cb44528ed1_raw.jpeg",
        "global_rating_score": 3.5
    },
    {
        "beer_name": "Victory at Sea",
        "brewery_name": "Ballast Point Brewing Company",
        "beer_type": "Porter - Imperial / Double Coffee",
        "beer_abv": "10",
        "beer_ibu": "60",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/6687",
        "brewery_url": "https://untappd.com/brewery/68",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2017_12_17/2dd7680bb3aa5b8a4baad10a037b566b_raw.jpeg",
        "global_rating_score": 4.06
    },
    {
        "beer_name": "Snickerdoodle",
        "brewery_name": "Sea Dog Brewing Company",
        "beer_type": "Porter - Other",
        "beer_abv": "7.7",
        "beer_ibu": "0",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/1233085",
        "brewery_url": "https://untappd.com/brewery/1125",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2017_12_24/68af746658ef313864cf1e075e15740d_raw.jpeg",
        "global_rating_score": 3.61
    },
    {
        "beer_name": "Pecan Nut Pie Porter",
        "brewery_name": "Sea Dog Brewing Company",
        "beer_type": "Porter - American",
        "beer_abv": "6.7",
        "beer_ibu": "0",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/2388555",
        "brewery_url": "https://untappd.com/brewery/1125",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2017_12_24/486d42408822f04d8fdcc129856d35ed_raw.jpeg",
        "global_rating_score": 3.49
    },
    {
        "beer_name": "Sexy Chocolate",
        "brewery_name": "Ocean Sun Brewing ",
        "beer_type": "Stout - Milk / Sweet",
        "beer_abv": "5.2",
        "beer_ibu": "30",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/2427574",
        "brewery_url": "https://untappd.com/brewery/235961",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photo/2017_12_29/da3e6e1cc4bdd52a30bbeeedc573ccd2_raw.jpg",
        "global_rating_score": 3.76
    },
    {
        "beer_name": "APA #3",
        "brewery_name": "Ocean Sun Brewing ",
        "beer_type": "Pale Ale - American",
        "beer_abv": "6",
        "beer_ibu": "42",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/2434213",
        "brewery_url": "https://untappd.com/brewery/235961",
        "brewery_country": "United States",
        "photo_url": null,
        "global_rating_score": 3.72
    },
    {
        "beer_name": "Modus Mandarina",
        "brewery_name": "Ska Brewing",
        "beer_type": "IPA - American",
        "beer_abv": "6.8",
        "beer_ibu": "65",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/632368",
        "brewery_url": "https://untappd.com/brewery/1150",
        "brewery_country": "United States",
        "photo_url": null,
        "global_rating_score": 3.66
    },
    {
        "beer_name": "Singletrack Rye Pale Ale",
        "brewery_name": "Boulder Beer",
        "beer_type": "Rye Beer",
        "beer_abv": "4.9",
        "beer_ibu": "38",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/1302985",
        "brewery_url": "https://untappd.com/brewery/160",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photos/2018_03_10/c4a369af22b58aec1cd19df6d00af1f6_raw.jpeg",
        "global_rating_score": 3.36
    },
    {
        "beer_name": "Peachwood IPA",
        "brewery_name": "Tommyknocker Brewery",
        "beer_type": "IPA - American",
        "beer_abv": "6",
        "beer_ibu": "72",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/2209443",
        "brewery_url": "https://untappd.com/brewery/4378",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photos/2018_03_12/5b6cba430ade380717e8b4ca191ab020_raw.jpeg",
        "global_rating_score": 3.72
    },
    {
        "beer_name": "Hard Root Beer",
        "brewery_name": "Peace Tree Brewing Company",
        "beer_type": "Root Beer",
        "beer_abv": "6",
        "beer_ibu": "0",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/2572656",
        "brewery_url": "https://untappd.com/brewery/4250",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photos/2018_05_19/4a45d0d15cf9960ca592a0ba025636b7_raw.jpeg",
        "global_rating_score": 3.66
    },
    {
        "beer_name": "Pseudo Sue",
        "brewery_name": "Toppling Goliath Brewing Co.",
        "beer_type": "Pale Ale - American",
        "beer_abv": "5.8",
        "beer_ibu": "45",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/65644",
        "brewery_url": "https://untappd.com/brewery/7532",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photos/2018_06_15/a848914d1d55bb442950e4e5eda4e052_raw.jpeg",
        "global_rating_score": 4.12
    },
    {
        "beer_name": "Copper Cat",
        "brewery_name": "Firetrucker Brewery",
        "beer_type": "Hard Ginger Beer",
        "beer_abv": "6.5",
        "beer_ibu": "0",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/2622834",
        "brewery_url": "https://untappd.com/brewery/126926",
        "brewery_country": "United States",
        "photo_url": null,
        "global_rating_score": 3.39
    },
    {
        "beer_name": "First Crush",
        "brewery_name": "Fishback & Stephenson",
        "beer_type": "Cider - Other Fruit",
        "beer_abv": "4.2",
        "beer_ibu": "0",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/2321065",
        "brewery_url": "https://untappd.com/brewery/343429",
        "brewery_country": "United States",
        "photo_url": null,
        "global_rating_score": 3.84
    },
    {
        "beer_name": "Blueberry Hefeweizen",
        "brewery_name": "Second State Brewing",
        "beer_type": "Wheat Beer - Hefeweizen",
        "beer_abv": "0",
        "beer_ibu": "0",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/2704583",
        "brewery_url": "https://untappd.com/brewery/55518",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photos/2018_06_16/6feb10a02a3b4dfc0007cedeb489667b_raw.jpeg",
        "global_rating_score": 3.54
    },
    {
        "beer_name": "Hannah",
        "brewery_name": "Exile Brewing Company",
        "beer_type": "Wheat Beer - Hefeweizen",
        "beer_abv": "6",
        "beer_ibu": "12",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/218980",
        "brewery_url": "https://untappd.com/brewery/37808",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photos/2018_06_28/c23b308072f72f2bd37203e64de34665_raw.jpeg",
        "global_rating_score": 3.59
    },
    {
        "beer_name": "White",
        "brewery_name": "Funkwerks",
        "beer_type": "Wheat Beer - Witbier / Blanche",
        "beer_abv": "4.8",
        "beer_ibu": "0",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/35253",
        "brewery_url": "https://untappd.com/brewery/8946",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photos/2018_07_09/bc67955b1020e6d5054685cd8a4a2b91_raw.jpeg",
        "global_rating_score": 3.61
    },
    {
        "beer_name": "Witches Tit Wit",
        "brewery_name": "Hideaway Park Brewery",
        "beer_type": "Wheat Beer - Witbier / Blanche",
        "beer_abv": "4.8",
        "beer_ibu": "26",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/763308",
        "brewery_url": "https://untappd.com/brewery/141977",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photos/2018_07_12/bb8d4951c90c52f1010cf7efa86b2df7_raw.jpeg",
        "global_rating_score": 3.56
    },
    {
        "beer_name": "BruBrew IPA",
        "brewery_name": "Hideaway Park Brewery",
        "beer_type": "IPA - American",
        "beer_abv": "7.3",
        "beer_ibu": "86",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/756667",
        "brewery_url": "https://untappd.com/brewery/141977",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photos/2018_07_12/c12d04adbe6b32ffb1fd2918153a5a89_raw.jpeg",
        "global_rating_score": 3.66
    },
    {
        "beer_name": "English Coffee Mild",
        "brewery_name": "Hideaway Park Brewery",
        "beer_type": "Stout - Coffee",
        "beer_abv": "4.2",
        "beer_ibu": "0",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/2744938",
        "brewery_url": "https://untappd.com/brewery/141977",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photos/2018_07_12/29dcd41154ad6149d86e42ba059c5b4a_raw.jpeg",
        "global_rating_score": 3.55
    },
    {
        "beer_name": "Mango Cart",
        "brewery_name": "Golden Road Brewing",
        "beer_type": "Fruit Beer",
        "beer_abv": "4",
        "beer_ibu": "10",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/1919131",
        "brewery_url": "https://untappd.com/brewery/16302",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photos/2018_09_15/2520f0e27c338c8c64c111e11abb0e3d_raw.jpeg",
        "global_rating_score": 3.8
    },
    {
        "beer_name": "Rook's Breakfast Porter",
        "brewery_name": "Reclaimed Rails Brewing Company",
        "beer_type": "Porter - American",
        "beer_abv": "7",
        "beer_ibu": "17",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/1953565",
        "brewery_url": "https://untappd.com/brewery/236045",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photos/2018_10_27/faf6f1f9a82d60efbfbd74e3d9748e50_raw.jpg",
        "global_rating_score": 3.96
    },
    {
        "beer_name": "Cucumber Kölsch",
        "brewery_name": "Reclaimed Rails Brewing Company",
        "beer_type": "Kölsch",
        "beer_abv": "4.5",
        "beer_ibu": "14",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/2796873",
        "brewery_url": "https://untappd.com/brewery/236045",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photos/2018_10_27/79d6e62bf5a03b373718ce0d89b76575_raw.jpg",
        "global_rating_score": 3.68
    },
    {
        "beer_name": "Molé Merlin",
        "brewery_name": "Firestone Walker Brewing Company",
        "beer_type": "Stout - Milk / Sweet",
        "beer_abv": "5.3",
        "beer_ibu": "34",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/2496756",
        "brewery_url": "https://untappd.com/brewery/524",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photos/2018_12_29/8656ceca6ed951069d20164cfdd39694_raw.jpg",
        "global_rating_score": 3.87
    },
    {
        "beer_name": "Two Hearted IPA",
        "brewery_name": "Bell's Brewery",
        "beer_type": "IPA - American",
        "beer_abv": "7",
        "beer_ibu": "55",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/4133",
        "brewery_url": "https://untappd.com/brewery/2507",
        "brewery_country": "United States",
        "photo_url": null,
        "global_rating_score": 3.95
    },
    {
        "beer_name": "The Corruption",
        "brewery_name": "DC Brau Brewing Company",
        "beer_type": "IPA - American",
        "beer_abv": "6.5",
        "beer_ibu": "80",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/50058",
        "brewery_url": "https://untappd.com/brewery/10489",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photos/2019_06_19/057176fc22408cfc515c707c641f0004_raw.jpg",
        "global_rating_score": 3.58
    },
    {
        "beer_name": "Final Glide",
        "brewery_name": "Lost Rhino Brewing Company",
        "beer_type": "Wheat Beer - Hefeweizen",
        "beer_abv": "5.4",
        "beer_ibu": "14",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/379000",
        "brewery_url": "https://untappd.com/brewery/11259",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photos/2019_06_19/95ec1a4d100240645df95165d0678bc5_raw.jpg",
        "global_rating_score": 3.6
    },
    {
        "beer_name": "World Wide Stout",
        "brewery_name": "Dogfish Head Craft Brewery",
        "beer_type": "Stout - Imperial / Double",
        "beer_abv": "18",
        "beer_ibu": "70",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/6730",
        "brewery_url": "https://untappd.com/brewery/459",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photos/2019_06_24/eae4e5da3b15001415142ed85f0dc3f6_raw.jpg",
        "global_rating_score": 4.23
    },
    {
        "beer_name": "Tornadic",
        "brewery_name": "Torrent Brewing Company",
        "beer_type": "IPA - American",
        "beer_abv": "7.6",
        "beer_ibu": "63",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/1658509",
        "brewery_url": "https://untappd.com/brewery/187023",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photos/2019_07_20/987367d08970dc559265044785299cb6_raw.jpg",
        "global_rating_score": 3.58
    },
    {
        "beer_name": "Fuzzy Memory",
        "brewery_name": "Torrent Brewing Company",
        "beer_type": "Sour - Other",
        "beer_abv": "4.8",
        "beer_ibu": "0",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/2990075",
        "brewery_url": "https://untappd.com/brewery/187023",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photos/2019_07_20/f32bcf5401d751be5ab890a22096e4f5_raw.jpg",
        "global_rating_score": 3.9
    },
    {
        "beer_name": "Root Beer",
        "brewery_name": "Torrent Brewing Company",
        "beer_type": "Root Beer",
        "beer_abv": "0",
        "beer_ibu": "0",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/1019398",
        "brewery_url": "https://untappd.com/brewery/187023",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photos/2019_07_20/adc1551fdee6ac680889795b45048d29_raw.jpg",
        "global_rating_score": 4.18
    },
    {
        "beer_name": "O.G. Apple",
        "brewery_name": "Jefferson County Ciderworks",
        "beer_type": "Cider - Traditional / Apfelwein",
        "beer_abv": "6.2",
        "beer_ibu": "0",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/2296729",
        "brewery_url": "https://untappd.com/brewery/229501",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photos/2019_07_20/d57ec9be841a478dcca9aacad931f24b_raw.jpg",
        "global_rating_score": 3.63
    },
    {
        "beer_name": "Georgia Peach",
        "brewery_name": "UFO Beer",
        "beer_type": "Wheat Beer - Hefeweizen",
        "beer_abv": "5.1",
        "beer_ibu": "10",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/2654575",
        "brewery_url": "https://untappd.com/brewery/469421",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photos/2019_10_26/f3f1e73b354af7d8af0b8c11c1452353_raw.jpg",
        "global_rating_score": 3.65
    },
    {
        "beer_name": "High Noon Watermelon",
        "brewery_name": "High Noon Spirits Company",
        "beer_type": "Hard Seltzer",
        "beer_abv": "4.5",
        "beer_ibu": "0",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/3787410",
        "brewery_url": "https://untappd.com/brewery/465061",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photos/2020_05_21/d0c5c6f281735747d03cf01c95751310_raw.jpg",
        "global_rating_score": 3.87
    },
    {
        "beer_name": "Neon Hazy IPA",
        "brewery_name": "Barn Town Brewing",
        "beer_type": "IPA - New England / Hazy",
        "beer_abv": "6.5",
        "beer_ibu": "0",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/2556169",
        "brewery_url": "https://untappd.com/brewery/237752",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photos/2020_12_07/e9c25350c05babf52ea3dfb10069675c_raw.jpg",
        "global_rating_score": 4.05
    },
    {
        "beer_name": "Plumello",
        "brewery_name": "East Grove Farms",
        "beer_type": "Mead - Melomel",
        "beer_abv": "10",
        "beer_ibu": "0",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/2681962",
        "brewery_url": "https://untappd.com/brewery/229496",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photos/2021_05_14/0cfb5696a22efb90e1fbe20e3610d111_raw.jpg",
        "global_rating_score": 3.77
    },
    {
        "beer_name": "Just the TIPA",
        "brewery_name": "Our Lady Of Perpetual Hops",
        "beer_type": "IPA - Triple",
        "beer_abv": "9.2",
        "beer_ibu": "19",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/3621897",
        "brewery_url": "https://untappd.com/brewery/407491",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photos/2021_08_29/9e992c5aebf51a68113cbcf4af084969_raw.jpg",
        "global_rating_score": 3.91
    },
    {
        "beer_name": "Big Daddy Dunkle",
        "brewery_name": "Our Lady Of Perpetual Hops",
        "beer_type": "Wheat Beer - Dunkelweizen",
        "beer_abv": "6",
        "beer_ibu": "16",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/3496802",
        "brewery_url": "https://untappd.com/brewery/407491",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photos/2021_08_29/f43c0e4cb9045e18a3b51f119787eb0d_raw.jpg",
        "global_rating_score": 3.75
    },
    {
        "beer_name": "Session Mead with Black Currant",
        "brewery_name": "Noble Cider",
        "beer_type": "Mead - Session / Short",
        "beer_abv": "5",
        "beer_ibu": "0",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/3390527",
        "brewery_url": "https://untappd.com/brewery/77407",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photos/2022_08_01/1747ec0fbfeba580d3b534bdd173b3c9_raw.jpg",
        "global_rating_score": 3.75
    },
    {
        "beer_name": "Legacy Lager",
        "brewery_name": "One World Brewing",
        "beer_type": "Lager - Pale",
        "beer_abv": "4",
        "beer_ibu": "0",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/4251671",
        "brewery_url": "https://untappd.com/brewery/130398",
        "brewery_country": "United States",
        "photo_url": null,
        "global_rating_score": 3.57
    },
    {
        "beer_name": "Original Alcoholic Ginger Beer",
        "brewery_name": "John Crabbie & Co",
        "beer_type": "Hard Ginger Beer",
        "beer_abv": "4.8",
        "beer_ibu": "0",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/18526",
        "brewery_url": "https://untappd.com/brewery/5016",
        "brewery_country": "Scotland",
        "photo_url": null,
        "global_rating_score": 3.46
    },
    {
        "beer_name": "CitraGenesis",
        "brewery_name": "Terre Haute Brewing Company",
        "beer_type": "Wheat Beer - American Pale Wheat",
        "beer_abv": "5",
        "beer_ibu": "15",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/3292736",
        "brewery_url": "https://untappd.com/brewery/268632",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photos/2023_03_15/44591a4f1e1c5e3371ddf2eb5a7369e5_raw.jpg",
        "global_rating_score": 3.7
    },
    {
        "beer_name": "Cryo IPA",
        "brewery_name": "Napali Brewing Co.",
        "beer_type": "IPA - American",
        "beer_abv": "6.9",
        "beer_ibu": "80",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/5372681",
        "brewery_url": "https://untappd.com/brewery/531392",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photos/2023_05_25/bc4afe8adecb712ddc19d5e0212e4904_raw.jpg",
        "global_rating_score": 0
    },
    {
        "beer_name": "Lilikoi Ale",
        "brewery_name": "Kaua‘i Island Brewing Company",
        "beer_type": "Golden Ale - Other",
        "beer_abv": "5",
        "beer_ibu": "25",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/202955",
        "brewery_url": "https://untappd.com/brewery/34159",
        "brewery_country": "United States",
        "photo_url": null,
        "global_rating_score": 3.59
    },
    {
        "beer_name": "Big Wave",
        "brewery_name": "Kona Brewing Hawaii",
        "beer_type": "Golden Ale - American",
        "beer_abv": "4.4",
        "beer_ibu": "21",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/9657",
        "brewery_url": "https://untappd.com/brewery/1988",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photos/2023_05_29/bce92afde3247935eef224925ed890de_raw.jpg",
        "global_rating_score": 3.52
    },
    {
        "beer_name": "Lemongrass Luau",
        "brewery_name": "Kona Brewing Hawaii",
        "beer_type": "Spiced / Herbed Beer",
        "beer_abv": "5",
        "beer_ibu": "20",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/67420",
        "brewery_url": "https://untappd.com/brewery/1988",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photos/2023_05_29/4a986cfe7c846c36a8a4e7fdebb60043_raw.jpg",
        "global_rating_score": 3.53
    },
    {
        "beer_name": "Hawaiian Lemongrass",
        "brewery_name": "Ola Brew Company",
        "beer_type": "Hard Seltzer",
        "beer_abv": "4.8",
        "beer_ibu": "0",
        "rating_score": "4",
        "beer_url": "https://untappd.com/beer/3390207",
        "brewery_url": "https://untappd.com/brewery/365476",
        "brewery_country": "United States",
        "photo_url": "https://untappd.s3.amazonaws.com/photos/2023_05_30/2708e0f4d72eb09c7995cf42b27a163e_raw.jpg",
        "global_rating_score": 3.78
    }
]

export default untappdFiltered;