import { useTheme } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import Contact from './Contact';


export default function About (){
    const theme = useTheme();
    return(
        <div>
            <Typography variant="h3" color={theme.palette.secondary.main}>About</Typography>
            <Typography variant="h5" color={theme.palette.primary.main}>This looks like a Job for Me</Typography>
            <Grid container spacing={1} alignItems='center' justifyContent="center">
            <Grid item xs={1}></Grid>
                <Grid item xs={10}>
            <Typography color={theme.palette.warning.main}>3+ years experience at a tech startup. Javascript React, SQL, HTML, CSS, C# .Net.
Analytical, solution-focused problem solver. Experience diagnosing bugs and
managing people and projects.</Typography>
</Grid>
<Grid item xs={1}></Grid>
</Grid>
<br></br>
<br></br>
<Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={12} md={2}>
                <Typography color={theme.palette.secondary.main}>2020-Current</Typography></Grid>
                <Grid item xs={12} md={2}>
                    <Typography color={theme.palette.primary.main}>Technical Support Engineer, RxLightning</Typography>
                    </Grid>
                <Grid item xs={12} md={8}>
                    <Typography color={theme.palette.secondary.main} align='left'>Coded bug fixes in JS and C# and headed database management to ensure customer satisfaction.</Typography>
                    <Typography color={theme.palette.secondary.main} align='left'>Headed database maintenance in early days of tech startup</Typography>
                    <Typography color={theme.palette.secondary.main} align='left'>Handled Tier 2 and Tier 3 support tickets -- tickets that require code or data work for resolution  -- to ensure customer satisfaction</Typography>
<Typography color={theme.palette.secondary.main} align='left'>Assisted development and deployment process, providing details, context, and quality assurance on development tickets.</Typography>
<Typography color={theme.palette.secondary.main} align='left'>Created training guides and resources and participated in onboarding of new clients.</Typography>
<Typography color={theme.palette.secondary.main} align='left'>Headed team of 3 data analysts.</Typography>
                    </Grid>
            </Grid>
            <br></br>
<br></br>
<Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={12} md={2}>
                <Typography color={theme.palette.secondary.main}>2013-2020</Typography></Grid>
                <Grid item xs={12} md={2}>
                    <Typography color={theme.palette.primary.main}>School Psychologist, Heartland Area Education Agency</Typography>
                    </Grid>
                <Grid item xs={12} md={8}>
                    <Typography color={theme.palette.secondary.main} align='left'>Analyzed academic and behavioral data to make recommendations for
systemic-level changes to improve educational experience of students.</Typography>
                    </Grid>
            </Grid>
            <br></br>
<br></br>
<Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={12} md={2}>
                <Typography color={theme.palette.secondary.main}>2005-2008</Typography></Grid>
                <Grid item xs={12} md={2}>
                    <Typography color={theme.palette.primary.main}>Real Estate Appraiser, Higgs Real Estate Services</Typography>
                    </Grid>
                <Grid item xs={12} md={8}>
                    <Typography color={theme.palette.secondary.main} align='left'>Analyzed housing and market trends to determine appropriate fair market
value for real estate.</Typography>
                    </Grid>
            </Grid>
            <br></br>
            <br></br>
            <Typography variant="h5" color={theme.palette.primary.main}>So Everybody, just Follow me</Typography>
            <Typography color={theme.palette.secondary.main}><a href='https://bold.pro/my/tyler-higgs-231001165318/653'>Resume</a></Typography>
            <Typography color={theme.palette.secondary.main}><a href='https://www.linkedin.com/in/tylerhiggsdev/'>LinkedIn</a></Typography>
            <Typography color={theme.palette.secondary.main}><a href='/Contact'>Contact Page</a></Typography>
            <Typography color={theme.palette.secondary.main}><a href='https://anotherinternetriddlegame.com/'>Baffler: Another Internet Riddle Game</a></Typography>
            <Typography color={theme.palette.secondary.main}><a href='https://www.quidquo.org/finance_search/index.html'>QuidQuo Iowa Campaign Finance Search</a></Typography>
            <br></br>
            <br></br>
            <Typography variant="h5" color={theme.palette.primary.main}>Cause We Need a Little Controversy</Typography>
            <Grid container spacing={1} alignItems='center' justifyContent="center">
            <Grid item xs={1} md={4}></Grid>
                <Grid item xs={10} md={6}>
            <Typography color={theme.palette.secondary.main} align="left">🌭 NOT a sandwich</Typography>
            <Typography color={theme.palette.secondary.main} align="left">📱 Android is better than iPhone</Typography>
            <Typography color={theme.palette.secondary.main} align="left">🥣 Cereal is not a soup</Typography>
            <Typography color={theme.palette.secondary.main} align="left">🖼️ Gif (hard G) not jiff</Typography>
            <Typography color={theme.palette.secondary.main} align="left">💧 Water is wet</Typography>
            <Typography color={theme.palette.secondary.main} align="left">🧻 Over (but also bidet)</Typography>
            <Typography color={theme.palette.secondary.main} align="left">🧦 Sock, sock, shoe, shoe</Typography>
            <Typography color={theme.palette.secondary.main} align="left">🚂 Chugga chugga chugga chugga, chugga chugga chugga chugga, CHOO CHOO! It has to be 8.</Typography>
            <Typography color={theme.palette.secondary.main} align="left">🍰 Cheesecake is a pie</Typography>
            <Typography color={theme.palette.secondary.main} align="left">👗 White and Gold (but I acknowledge I was wrong)</Typography>
            <Typography color={theme.palette.secondary.main} align="left">🔊 Yanny not Laurel</Typography>
            <Typography color={theme.palette.secondary.main} align="left">🍕 Chicago-style *thin* crust</Typography>
            <Typography color={theme.palette.secondary.main} align="left">🍍 Yummy on pizza</Typography>
            <Typography color={theme.palette.secondary.main} align="left">🍅 Ketchup fine on hotdogs</Typography>
            <Typography color={theme.palette.secondary.main} align="left">🍴 Doesn't matter if it's a spoon or fork, as long as you can get mac and cheese to your face. Probably spoon for easier shoveling.</Typography>
            <Typography color={theme.palette.secondary.main} align="left">🍫 Brownies...middle piece</Typography>
            <Typography color={theme.palette.secondary.main} align="left">🍗 Thigh</Typography>
            <Typography color={theme.palette.secondary.main} align="left">🍔 Bottom, mustard, onion (diced), patty, cheese, pickle, tomato (opt), lettuce, ketchup, top. The important things here are that mustard and onion must be on the opposite side if the burger from the ketchup and pickles.</Typography>
            <Typography color={theme.palette.secondary.main} align="left">🥪 Diagonal cut. 2 diagonal cuts for someone you love.</Typography>
            <Typography color={theme.palette.secondary.main} align="left">🐕 Team dog.</Typography>
            <Typography color={theme.palette.secondary.main} align="left">🪽 Flying is better than invisibility</Typography>
            <Typography color={theme.palette.secondary.main} align="left">🔱 Percy Jackson over Harry Potter (but both good)</Typography>
            <Typography color={theme.palette.secondary.main} align="left">🥶 Too cold is better than too hot</Typography>
            <Typography color={theme.palette.secondary.main} align="left">🪐 Pluto is not a planet</Typography>
            <Typography color={theme.palette.secondary.main} align="left">👽 Probably exist somewhere, but haven't visited</Typography>
            <Typography color={theme.palette.secondary.main} align="left">🥚 Team egg</Typography>
            <Typography color={theme.palette.secondary.main} align="left">🍂 Autumn is best</Typography>
            <Typography color={theme.palette.secondary.main} align="left">🍪 Proper way to eat an Oreo: twist apart and recombine to make double stuffed (or quadrupple stuffed if you started with doubles). Dunk in milk until nearly ready to disintegrate.</Typography>
            </Grid>
            <Grid item xs={1} md={2}></Grid>
            </Grid>
            <br></br>
            <br></br>
            <Typography variant="h5" color={theme.palette.primary.main}>Cause it feels so Empty without Me</Typography>
            <br></br>
            <br></br>
            <Contact />
            

        </div>
    )
};