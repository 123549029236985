import * as React from "react";
import { Grid, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';

export default function Tools(){
    const theme = useTheme();

    return(
        <div>
              <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={1} md={3}></Grid>
                <Grid item xs={10} md={6}>
                    <a href ='/tools/lunchselector'>
                  <Typography variant="h3" color={theme.palette.secondary.main} textAlign="center">Lunch Selector</Typography>
                  </a>
                  </Grid>
            <Grid item xs={1} md={3}></Grid>
            </Grid>
            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={1} md={3}></Grid>
                <Grid item xs={10} md={6}>
                    <a href ='/beers'>
                  <Typography variant="h3" color={theme.palette.primary.main} textAlign="center">Beer Helper</Typography>
                  </a>
            </Grid>
            <Grid item xs={1} md={3}></Grid>
            </Grid>
        </div>
    )
}