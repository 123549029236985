import { FormControl } from "@mui/material"
import { FormLabel } from "@mui/material"
import { TextField } from "@mui/material"
import { Button } from "@mui/material"
import { Grid, Typography } from '@mui/material'
import { useTheme } from '@emotion/react'
import { useState } from "react"
import SendIcon from '@mui/icons-material/Send';
import emailjs from '@emailjs/browser';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


export default function Contact() {

    const theme = useTheme();

    const [responseMessage, setResponseMessage] = useState(undefined);
    const [open, setOpen] = useState(false);
    const [snackbarType, setSnackbarType] = useState(undefined);
    let [formData, setFormData] = useState({
        personName: " ",
        email: " ",
        subject: " ",
        message: " "
    })
    const handleChange = (event) => {
        let value = event.target.value;
        let name = event.target.name;

        setFormData((prevalue) => {
            return {
                ...prevalue,
                [name]: value
            }
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        emailjs.send(
            process.env.REACT_APP_EMAILJS_SERVICE_ID,
            process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
            formData,
            process.env.REACT_APP_EMAILJS_USER_ID
        )
            .then((result) => {
                setResponseMessage('Thank you, your message was received!');
                setSnackbarType('success');
                setFormData({
                    personName: " ",
                    email: " ",
                    subject: " ",
                    message: " "
                })
            }, (error) => {
                setResponseMessage(`Whoopsy doodles, there was an error:\n${error.text}`);
                setSnackbarType('error');
            }).then(
                setOpen(true));
    }



    function handleClose() {
        setOpen(false);
    };

    return (
        <div>
            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={1} md={3}></Grid>
                <Grid item xs={10}md={6}>
                    <Typography variant="h3" color={theme.palette.secondary.main}>Contact</Typography>
                    <Typography color={theme.palette.primary.main}>Have a question or want to work together?</Typography>
                    <FormControl fullWidth>
                        <FormLabel>Name</FormLabel>
                        <TextField required type='text' variant='outlined' onChange={handleChange} name='personName'
                            sx={{
                                "& .MuiInputLabel-root": { color: 'gray' },//styles the label
                                "& .MuiOutlinedInput-root": {
                                    "& > fieldset": { borderColor: "gray" },
                                },
                                input: {
                                    color: theme.palette.primary.main,
                                }
                            }} label='Your Name'></TextField>
                        <FormLabel>Email</FormLabel>
                        <TextField required type='email' variant='outlined' onChange={handleChange} name='email'
                            sx={{
                                "& .MuiInputLabel-root": { color: 'gray' },//styles the label
                                "& .MuiOutlinedInput-root": {
                                    "& > fieldset": { borderColor: "gray" },
                                },
                                input: {
                                    color: theme.palette.primary.main,
                                }
                            }}
                            label='Your Email'></TextField>
                        <FormLabel>Subject</FormLabel>
                        <TextField required type='text' variant='outlined' onChange={handleChange} name='subject'
                            sx={{
                                "& .MuiInputLabel-root": { color: 'gray' },//styles the label
                                "& .MuiOutlinedInput-root": {
                                    "& > fieldset": { borderColor: "gray" },
                                },
                                input: {
                                    color: theme.palette.primary.main,
                                }
                            }} label='Subject'></TextField>
                        <FormLabel>Message</FormLabel>
                        <TextField required multiline rows={10} type='text' variant='outlined' onChange={handleChange} name='message'
                            inputProps={{
                                style: {
                                    color: theme.palette.primary.main
                                }
                            }}
                            sx={{
                                "& .MuiInputLabel-root": { color: 'gray' },//styles the label
                                "& .MuiOutlinedInput-root": {
                                    "& > fieldset": { borderColor: "gray" },
                                },
                                input: {
                                    color: theme.palette.primary.main,
                                }
                            }}
                            label='Message'
                        ></TextField>
                        <br></br>
                        <Button variant="outlined" onClick={handleSubmit} endIcon={<SendIcon />}>Submit</Button>
                    </FormControl>
                </Grid>
                <Grid item xs={1} md={3}></Grid>
            </Grid>
            <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                sx={{ width: "100%" }}
            >
                <MuiAlert onClose={handleClose} severity={snackbarType} sx={{ width: '50%', fontSize: '2rem' }}>
                    {responseMessage}
                </MuiAlert>
            </Snackbar>
        </div>
    )
};