import { FormControl } from "@mui/material"
import { FormLabel } from "@mui/material"
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import * as React from 'react';
import Button from '@mui/material/Button';
import { useTheme } from '@emotion/react'
import { Typography } from '@mui/material'
import { useEffect } from "react";

const lunchOptions = [{
    "name": "BoomTown",
    "isLunch": true,
    "isHappy": true,
    "isDinner": true,
    "type": "pub"
},
{
    "name": "Exchange",
    "isLunch": true,
    "isHappy": true,
    "isDinner": true,
    "type": "pub"
},
{
    "name": "Floyd County Brewing Company",
    "isLunch": true,
    "isHappy": true,
    "isDinner": true,
    "type": "pub",
    "limitations": "closed Mon"
},
{
    "name": "RecBar 812",
    "isLunch": true,
    "isHappy": true,
    "isDinner": true,
    "type": "pub",
    "limitations": "No lunch M or Tue"
},
{
    "name": "Smokin' Frank's BBQ",
    "isLunch": true,
    "isHappy": false,
    "isDinner": false,
    "type": "bbq",
    "limitations": "W Th Only"
},
{
    "name": "Aladdins",
    "isLunch": true,
    "isHappy": false,
    "isDinner": true,
    "type": "Mediterranean",
    "limitations": "Closed M Tue"
},
{
    "name": "Dragon King's Daughter",
    "isLunch": true,
    "isHappy": false,
    "isDinner": true,
    "type": "Mexican Japanese fusion"
},
{
    "name": "Agave and Rye",
    "isLunch": true,
    "isHappy": true,
    "isDinner": true,
    "type": "Mexican"
},
{
    "name": "La Catrina",
    "isLunch": true,
    "isHappy": true,
    "isDinner": true,
    "type": "Mexican"
},
{
    "name": "OhChae",
    "isLunch": true,
    "isHappy": false,
    "isDinner": true,
    "type": "Korean Fusion",
    "limitations": "Closed Th Fri. Lunch only W."
},
{
    "name": "Toast On Market",
    "isLunch": true,
    "isHappy": false,
    "isDinner": false,
    "type": "Diner",
    "limitations": "Closed M Tue"
},
{
    "name": "Lady Tron",
    "isLunch": true,
    "isHappy": false,
    "isDinner": false,
    "type": "Diner",
    "limitations": "Closed Wed"
},
{
    "name": "Wild Eggs",
    "isLunch": true,
    "isHappy": false,
    "isDinner": false,
    "type": "Diner"
},
{
    "name": "Waffle House",
    "isLunch": true,
    "isHappy": false,
    "isDinner": true,
    "type": "Diner"
},
{
    "name": "Parlour Pizza",
    "isLunch": true,
    "isHappy": false,
    "isDinner": true,
    "type": "Pizza"
},
{
    "name": "8th St. Pizza",
    "isLunch": true,
    "isHappy": false,
    "isDinner": true,
    "type": "Pizza"
},
{
    "name": "Outcast Fish and Oyster Bar",
    "isLunch": true,
    "isHappy": true,
    "isDinner": true,
    "type": "Seafood"
},
{
    "name": "Pints & Union",
    "isLunch": false,
    "isHappy": true,
    "isDinner": false,
    "type": "pub"
},
{
    "name": "The Earl",
    "isLunch": false,
    "isHappy": true,
    "isDinner": true,
    "type": "Pub"
},
{
    "name": "High Street Apothecary",
    "isLunch": false,
    "isHappy": true,
    "isDinner": false,
    "type": "Cocktail Bar",
    "limitations": "Closed M Tue"
},
{
    "name": "Baers City Winery & Brick Oven Kitchen",
    "isLunch": false,
    "isHappy": true,
    "isDinner": true,
    "type": "Closed M Tue"
},
{
    "name": "Hitching Post Tavern",
    "isLunch": false,
    "isHappy": true,
    "isDinner": false,
    "type": "Pub"
},
{
    "name": "Hugh E. Birs",
    "isLunch": false,
    "isHappy": true,
    "isDinner": false,
    "type": "Pub"
},
{
    "name": "Board & You Wine Bar and Bistro",
    "isLunch": false,
    "isHappy": true,
    "isDinner": true,
    "type": "Wine and Charceuterie"
},
{
    "name": "Spinelli's Pizza",
    "isLunch": false,
    "isHappy": true,
    "isDinner": true,
    "type": "Pizza",
    "limitations": "Closed M Tue"
},
{
    "name": "Bella Roma",
    "isLunch": false,
    "isHappy": false,
    "isDinner": true,
    "type": "Italian",
    "limitations": "Closed Mon"
},
{
    "name": "Brooklyn and the Butcher",
    "isLunch": false,
    "isHappy": false,
    "isDinner": true,
    "type": "Pizza",
    "limitations": "Closed Mon"
},
{
    "name": "MESA, A Live Dining Show",
    "isLunch": false,
    "isHappy": false,
    "isDinner": true,
    "type": "Pizza",
    "limitations": "Wed Thur Fri by reservation only"
}];



export default function LunchSelector (){

    const theme = useTheme();

    const [diningPreferences, setDiningPreferences] = React.useState({
        isLunch: false,
        isHappy: false,
        isDinner: false,
      });

    const [diningOptions, setDiningOptions] = React.useState([{}])
    
      const handleChange = (event) => {
        setDiningPreferences({
          ...diningPreferences,
          [event.target.name]: event.target.checked,
        });
      };
    
      const { isLunch, isHappy, isDinner } = diningPreferences;
      const error = [isLunch, isHappy, isDinner].filter((v) => v).length < 1;
    
      const handleClick = () => {
        const randomOption = [diningOptions[Math.floor(Math.random() * (diningOptions.length))]];
        setDiningOptions(randomOption);
      }

      useEffect(()=>{
        const newOptions = lunchOptions.filter(lunchoption=>{
            return (lunchoption.isLunch===diningPreferences.isLunch && diningPreferences.isLunch) ||
            (lunchoption.isHappy===diningPreferences.isHappy && diningPreferences.isHappy) ||
            (lunchoption.isDinner===diningPreferences.isDinner && diningPreferences.isDinner)
        });
        setDiningOptions(newOptions);
    }
        ,[diningPreferences])

    return(
        <div>
<FormControl fullWidth   
        required
        error={error}
        component="fieldset">
<FormLabel component="legend">Select at least One:</FormLabel>
<FormGroup sx={{position: 'flex',  flexDirection: 'row', justifyContent: 'center', alignItems:'center'}}>
<FormControlLabel label="Lunch" control={
              <Checkbox checked={isLunch} onChange={handleChange} name="isLunch" />
            }/>
<FormControlLabel label="Happy Hour" control={
              <Checkbox checked={isHappy} onChange={handleChange} name="isHappy" />
            } />
<FormControlLabel label="Dinner" control={
              <Checkbox checked={isDinner} onChange={handleChange} name="isDinner" />
            }/>
</FormGroup>
<Button variant="outlined" onClick={handleClick}>Show Random Option</Button>
                        </FormControl>
  {diningOptions?.map((option) => (
    <div key={option?.name ?? "default"}>
<br></br>
<Typography variant="h5" color={theme.palette.primary.main}>{option?.name ?? "Please select a filter option"}</Typography>
<Typography  color={theme.palette.secondary.main}>{option?.type}</Typography>
<Typography color={theme.palette.warning.main}>{option?.limitations}</Typography>
    </div>
  )
)}
        </div>
    );
}