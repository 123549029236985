import React from 'react'
import { useState, useEffect } from 'react';
import { Grid, Typography } from '@mui/material'
import { useTheme } from '@emotion/react'
import NounsForTyler from './NounsForTyler';
import Contact from './Contact';

let nounsList = NounsForTyler;
const orgNounListLength = nounsList.length;

export default function Home() {
    const theme = useTheme();
    const [nouns, setNouns] = useState([" ", " ", " ", " ", " ", " "]);
    const [dev, setDev] = useState(false);

    useEffect(() => {
        if (dev) {
            return;
        }
        const interval = setInterval(() => {
            if (nounsList.length < 1 || nounsList.length < (orgNounListLength - 20)) {
                const clearNouns = [" ", " ", " ", " ", " ", " "]
                setNouns(clearNouns);
                setDev(true);
            }
            else {
                const oldNouns = nouns;
                let newNouns = [" ", " ", " ", " ", " ", " "];
                oldNouns.forEach((e, i) => {
                    if (e === " " && Math.floor(Math.random() * 2) === 1 && nounsList.length > 0) {
                        const newNounIndex = Math.floor(Math.random() * nounsList.length)
                        const newNoun = nounsList[newNounIndex];
                        nounsList.splice(newNounIndex, 1)
                        newNouns[i] = newNoun
                    }
                    else if (e !== " " && Math.floor(Math.random() * 2) === 1) {
                        newNouns[i] = e
                    }
                    else newNouns[i] = " ";
                }
                )
                setNouns(newNouns);
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [nouns, dev]);

    return (
        <div>
            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={6}>
                    <Typography variant="h1" color={theme.palette.primary.main}>Tyler</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography width="100%" height="100%">{nouns[0]}</Typography>
                </Grid>
                <Grid item xs={3}></Grid>
            </Grid>
            <Grid container spacing={1} alignItems='center' justifyContent="center" grid-auto-rows= "1fr">
                <Grid item xs={3}>
                </Grid>
                <Grid item xs={6}>
                    <Typography width="100%" height="100%" >{nouns[1]}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography width="100%" height="100%">{nouns[2]}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={1} alignItems='center' justifyContent="center"><Grid item xs={3}>
            </Grid><Grid item xs={3}>
                    <Typography width="100%" height="100%">{nouns[3]}</Typography>
                </Grid><Grid item xs={6}> </Grid></Grid>
            <Grid container spacing={1} alignItems='center' justifyContent="center" grid-auto-rows= "1fr">
                <Grid item xs={3}>
                    <Typography width="100%" height="100%">{nouns[4]}</Typography>
                </Grid>
                <Grid item xs={3}></Grid>
                <Grid item xs={3}>
                    <Typography width="100%" height="100%">{nouns[5]}</Typography>
                </Grid>
                <Grid item xs={3}>
                </Grid>
            </Grid>
            <Grid container spacing={1} alignItems='center' justifyContent="center">
                <Grid item xs={5}>
                    {dev && (<Typography variant="h1" color={theme.palette.warning.main}>.dev</Typography>)}
                </Grid>
                <Grid item xs={7}>
                    <Typography variant="h1" color={theme.palette.secondary.main}>Higgs</Typography>
                </Grid>
            </Grid>
            {dev && <div> <br></br><br></br> <Contact /> </div> }
        </div>
    )
};