import React from 'react'
import { Outlet } from 'react-router-dom'
import Navbar from './navbar/Navbar'
import Footerbar from './navbar/Footerbar'

export default function RootLayout() {
return(
    <div className='container'>
    <Navbar />
    <Outlet />
    <div className='footer'>
    <Footerbar />
    </div>
    </div>
)
};