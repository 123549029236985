import { Typography } from "@mui/material"
import { useTheme } from '@emotion/react'
import punList from "../constants/PunList"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

export default function Puns (){

    const theme = useTheme();

    return(
        <div>
            <Typography variant="h3" color={theme.palette.secondary.main}>Puns</Typography>
            <Typography color={theme.palette.primary.main}>If you can't pun, do you even code?</Typography> 
            <Typography color={theme.palette.primary.main}>Documented proof of my contributions to various pun threads:</Typography>
            {punList.map((item, i) => (
                            <Accordion key={item.topic}>
                    <AccordionSummary key={item.topic+"summary"}
                    expandIcon={<ArrowForwardIosSharpIcon />}
                    aria-controls="panel1a-content"
                    id="panel2bh-header"
                    sx = {{
                        flexDirection: 'row-reverse',
                        '& .MuiAccordionSummary-expandIconWrapper .MuiSvgIcon-root': {
                            color: theme.palette.primary.main,
                          },
                    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                      transform: 'rotate(90deg)'
                    }}}
                    >
                    <Typography variant="h5" color={theme.palette.secondary.main} key={item.topic} align="left">{item.topic}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                            {item.puns.map((pun, i) => (
                                <Typography key={"pun" + i} display='list-item' color={theme.palette.primary.main} align="left" marginLeft={5}>{pun}</Typography>
                            ))}

                    </AccordionDetails>
                    </Accordion>
//                 {item.puns.map((pun, i)=>(
// <li key={"pun"+i.toString()}>{pun}</li>
//                 ))}
                    
                ))}
                  
            
            

        </div>
    )
};