import { Typography } from "@mui/material"
import { useTheme } from '@emotion/react'
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { useState } from "react";
import axios from 'axios';
import { useEffect } from "react";
import InfiniteScroll from 'react-infinite-scroll-component';
import { useRef } from "react";


export default function Dogs (){
    const theme = useTheme();
        const [dogImages, setDogImages] = useState([]);
        const instaUrl = useRef("https://graph.instagram.com/v18.0/17841462855930811/media?fields=id,media_type,media_url,premalink&access_token="+process.env.REACT_APP_INSTAGRAM_ACCESS_TOKEN);

    function fetchData (){
        axios.get(instaUrl.current)
          .then(response => {
            const newDogList = dogImages.concat(response.data.data.filter(dogImg=>dogImg.media_type === "IMAGE"));
            setDogImages(newDogList);
            instaUrl.current = response.data.paging.next
          })
          .catch(error => {
            const defaultDogs = dogImages.concat([
                {
                "id": "dog1", 
                "media_url": '/Dogs/20150608_130645.jpg'
                },
                {"id": "dog2", "media_url": '/Dogs/20150826_210103.jpg'},
                {"id": "dog3", "media_url": '/Dogs/20150905_093117.jpg'},
                {"id": "dog4", "media_url": '/Dogs/20150905_093144.jpg'},
                {"id": "dog5", "media_url": '/Dogs/20151023_210440.jpg'},
                {"id": "dog6", "media_url": '/Dogs/20151028_164412.jpg'},
                {"id": "dog7", "media_url": '/Dogs/20160202_094522-1.jpg'},
                {"id": "dog8", "media_url": '/Dogs/20160312_112153.jpg'},
                {"id": "dog9", "media_url": '/Dogs/20160314_143637.jpg'},
                {"id": "dog10", "media_url": '/Dogs/20160316_113004.jpg'},
                {"id": "dog11", "media_url": '/Dogs/PXL_20210916_233657310.jpg'},
                {"id": "dog12", "media_url": '/Dogs/PXL_20210916_233700845.MP.jpg'},
                {"id": "dog13", "media_url": '/Dogs/IMG_20200308_123156.jpg'},
                {"id": "dog14", "media_url": '/Dogs/IMG_20200308_124054.jpg'},
                {"id": "dog15", "media_url": '/Dogs/PXL_20210916_233701833.MP.jpg'},
                {"id": "dog16", "media_url": '/Dogs/PXL_20210917_223803878.jpg'},
                {"id": "dog17", "media_url": '/Dogs/PXL_20210919_152342607.MP.jpg'},
                {"id": "dog18", "media_url": '/Dogs/PXL_20210919_152344908.MP.jpg'},
                {"id": "dog19", "media_url": '/Dogs/PXL_20210919_165510982.jpg'},
                {"id": "dog20", "media_url": '/Dogs/PXL_20210920_011933608.jpg'},
                {"id": "dog21", "media_url": '/Dogs/PXL_20210920_011937827.jpg'},
                {"id": "dog22", "media_url": '/Dogs/PXL_20210924_011352895.jpg'},
                {"id": "dog23", "media_url": '/Dogs/PXL_20210925_212629011.jpg'},
                {"id": "dog24", "media_url": '/Dogs/PXL_20210925_212654381.jpg'},
                {"id": "dog25", "media_url": '/Dogs/IMG_20180626_131607.jpg'}]);
            setDogImages(defaultDogs);
            instaUrl.current = '';
          });
};

    useEffect(() => {
fetchData();
// eslint-disable-next-line
      }, []);



    return(
        <div>
            <Typography variant="h3" color={theme.palette.secondary.main}>Dogs</Typography>

            <div className="dogs">
                {dogImages ?
                <InfiniteScroll
                dataLength={dogImages?.length}
                next={fetchData}
                hasMore={instaUrl.current}
                endMessage={<Typography>All Dogs Loaded</Typography>}
                loader={<Typography>Loading...</Typography>}
                >
            <ImageList variant="woven" cols={3} rowHeight={164} align='center'>
      {dogImages?.map((item) => (
        <ImageListItem key={item.id}>
          <img
            srcSet={item.media_url}
            src={item.media_url}
            alt=""
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList> 
    </InfiniteScroll>
    : 'Loading...'}
           </div>

        </div>
    )
};