const NounsForTyler = [
    'School Psychologist',
    'Software Engineer',
    'Riddler',
    'Real Estate Appraiser',
    'Dog Dad',
    'Craft Beer Enthusiast',
    'Life-Long Learner',
    'Data Analyst',
    'Support Engineer',
    'Puzzle King',
    'Chess Amateur',
    'Pun Master'
]

export default NounsForTyler;